import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Breadcrumb, Layout, message, Space, Spin} from 'antd';

import './fonts.css';
import "antd/dist/antd.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as serviceWorker from './serviceWorker';

import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import Login from "./components/website/auth/Login.jsx";
import NotFound from './components/website/errors/NotFound';
import {Header} from "./components/website/Nav/header";
import {SidebarMenu} from "./components/website/Nav/sidebar";
import {ProjectsContent} from "./components/website/Projects/projects";
import {ProjectsHeader} from "./components/website/Projects/projects_header";
import ProjectPage from "./components/templateEditor/ProjectPage";

import SERVER_ADDRESS from "./config.jsx";
import * as csv from "csvtojson";

const {Content, Footer} = Layout;

export default class Index extends Component {

    // Cookies
    getCookieValue = key => {
        let keyValue = document.cookie
            .split(';')
            .filter((item) => item.trim().startsWith(key + '='))[0];
        if (keyValue !== undefined) {
            keyValue = keyValue.split('=')[1];
        } else {
            keyValue = ''
        }
        return keyValue
    };

    deleteCookieValue = key => {
        document.cookie = key + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    };

    setUserAuth = data => {
        let accessTime = new Date();
        accessTime.setTime(accessTime.getTime() + 300 * 1000); // Set access cookie lifetime - 5 min

        let refreshTime = new Date();
        refreshTime.setTime(refreshTime.getTime() + 3600 * 1000 * 24); // Set refresh cookie lifetime - 24 hours

        // Set cookies
        document.cookie = `refresh=${data.refresh}; path=/; expires=${refreshTime}`;
        document.cookie = `token=${data.access}; path=/; expires=${accessTime}`;
        this.setState({
            user: data.user
        });
    };

    logIn = data => {
        const json_data = JSON.stringify(data);
        this.baseFetchApi('/token/',
            {
                method: 'POST',
                body: json_data,
            },
            false
        ).then(
            data => {
                if (data !== undefined) {
                    if (data.detail !== undefined) {
                        message.warning({
                            content: data.detail,
                            duration: 3
                        });
                    } else {
                        message.success({
                            content: "Success login!",
                            duration: 3
                        });
                    }
                    this.setUserAuth(data);
                }
            }
        )
    };

    logOut = () => {
        this.deleteCookieValue('token');
        this.deleteCookieValue('refresh');
        this.setState({
            user: undefined
        });
    };

    getUserInfo = () => {
        const accessToken = this.getCookieValue("token");
        const refreshToken = this.getCookieValue("refresh");
        return this.baseFetchApi(`/current_user/`)
            .then(
                user => {
                    if (user.id) {
                        const user_info = {
                            refresh: refreshToken,
                            access: accessToken,
                            user: user
                        };
                        this.setUserAuth(user_info);
                        return user_info;
                    } else {
                        this.setState(
                            {error: `Error with getting user info.`},
                            () => {
                                if (this.state.user) {
                                    message.error({
                                        content: this.state.error,
                                        duration: 3
                                    });
                                }
                                // throw new Error(`[F:getUserInfo()] ${this.state.error} (User: ${user})`);
                            }
                        )
                    }
                }
            );
    };

    refreshToken = () => {
        return this.baseFetchApi(`/refresh/`, {
            method: "POST",
            body: JSON.stringify({
                refresh: this.getCookieValue('refresh')
            })
        }).then(
            data => {
                if (data.access) {
                    let future = new Date();
                    future.setTime(future.getTime() + 300 * 1000);
                    document.cookie = `token=${data.access}; path=/; expires=${future}`;
                    return data
                } else {
                    throw new Error(`[ refreshToken() ] error ${data}`)
                }
            }
        )
    };

    verifyToken = () => {
        const token = this.getCookieValue('token');
        return this.baseFetchApi(`/token/verify/`, {
            method: "POST",
            body: JSON.stringify({
                token: token
            })
        }, false)
            .then(
                data => {
                    if (!Object.entries(data).length) {
                        return token
                    } else {
                        this.setState(
                            {error: `Error with verify. ${data.detail}.`},
                            () => {
                                if (this.state.user) {
                                    message.error({
                                        content: this.state.error,
                                        duration: 3
                                    });
                                }
                                // throw new Error(`[F:verifyToken()] ${this.state.error}`);
                            }
                        )
                    }
                },
                err => {
                    message.error(err.message);
                }
            )
    };

    checkUserAuth = () => {
        return this.verifyToken()
            .then(
                () => this.getUserInfo(),
                () => this.refreshToken()
                    .then(
                        () => this.getUserInfo(),
                        () => {
                            this.setState({
                                user: undefined
                            })
                        }
                    )
            )
    };

    baseFetchApi = (url, options = {method: 'GET'}, auth = true) => {
        auth ?
            options['headers'] = new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getCookieValue('token')}`
            })
            :
            options['headers'] = new Headers({
                'Content-Type': 'application/json',
            });

        return fetch(SERVER_ADDRESS + url, options)
            .then(
                response => {
                    if (response.status !== 204) {
                        return response.json().catch(() => {
                                throw new Error(`${response.statusText} (${response.status}), URL:${url}`)
                            }
                        );
                    }
                },
                err => {
                    this.setState(
                        {
                            error: err.message
                        },
                        () => {
                            message.error({
                                content: this.state.error,
                                duration: 3
                            });
                        }
                    )
                }
            )
    };

    fetchApi = (url, options = {method: 'GET'}) => {
        return this.verifyToken()
            .then(
                () => this.baseFetchApi(url, options),
                () => this.refreshToken()
                    .then(
                        () => this.baseFetchApi(url, options),
                        () => {
                            this.setState({
                                user: undefined
                            })
                        }
                    )
            )
    };

    downloadFeed = url => {
        return fetch(url).then(resp => resp.text()).then(
            feed_data => {
                let delimiter = ',';
                if (url.endsWith('.tsv')) {
                    delimiter = '\t';
                }
                return csv({
                    delimiter: delimiter
                }).fromString(feed_data)
            },
            error => {
                message.error({
                    content: `Problem with feed. ${error}`,
                    duration: 3
                });
                throw new Error(`Problem with feed.\n${error}`);
            })
    };

    fetchFeed = feed_id => {
        let actual_this = this;
        return new Promise((resolve, reject) => {
            actual_this.fetchApi(`/feeds/${feed_id}/`)
                .then(data => {
                        data.detail !== "Not found." ? resolve(data) : reject(data)
                    },
                    error => {
                        reject(`[fetchFeed] Problem with database.\n${error.message}`)
                    });
        })
    };

    fetchProject = project_id => {
        let actual_this = this;
        return new Promise((resolve, reject) => {
            actual_this.fetchApi(`/projects/${project_id}/`)
                .then(data => {
                        data.detail !== "Not found." ? resolve(data) : reject(data)
                    },
                    error => {
                        reject(`[fetchProject] Problem with database.\n${error.message}`)
                    });
        })
    };

    fetchConfig = config_id => {
        let actual_this = this;
        return new Promise((resolve, reject) => {
            actual_this.fetchApi(`/configs/${config_id}/`)
                .then((data) => {
                        data.detail !== "Not found." ? resolve(data) : reject(data)
                    },
                    error => {
                        reject(`[fetchConfig] Problem with database.\n${error.message}`)
                    });
        })
    };

    handleSwitchMenuSelect = page => {
        this.setState({
            currentPage: page
        });
    }

    onCollapse = collapsed => {
        this.setState({collapsed});
    };

    componentDidMount() {
        this.checkUserAuth().then(
            () => {
                this.setState({loaded: true})
            },
            () => {
                this.setState({loaded: true})
            }
        )
    }

    state = {
        user: undefined,
        loaded: false,
        error: undefined,
        baseFetchApi: this.baseFetchApi,
        fetchApi: this.fetchApi,
        setUserAuth: this.setUserAuth,
        logOut: this.logOut,
        logIn: this.logIn,
        downloadFeed: this.downloadFeed,
        fetchFeed: this.fetchFeed,
        fetchProject: this.fetchProject,
        fetchConfig: this.fetchConfig,
        handleSwitchMenuSelect: this.handleSwitchMenuSelect,
        collapsed: false,
        currentPage: "index",
    };

    render() {
        return this.state.loaded ?
            <Router>
                <Layout style={{minHeight: '100vh'}}>
                    <Header
                        user={this.state.user}
                        logOut={this.logOut}
                        style={{position: 'fixed', zIndex: 1, width: '100%'}}
                    />
                    {
                        this.state.user ?

                            <Layout>
                                <SidebarMenu
                                    collapsed={this.state.collapsed}
                                    currentPage={this.state.currentPage}
                                    onCollapse={this.onCollapse}
                                />
                                <Space direction={"vertical"} style={{width: "100%"}}>
                                    <Content style={{margin: '0 16px'}}>
                                        <Switch>
                                            <Route exact path="/">
                                                <Redirect to={"/projects"}/>
                                            </Route>
                                            <Route
                                                exact path={"/projects"}
                                                render={props => <ProjectsPage {...props} backend={this.state}/>}
                                            />

                                            <Route
                                                exact path="/project/:project_id/design/new"
                                                render={() => <ProjectPage backend={this.state}/>}
                                            />
                                            <Route
                                                exact path="/project/:project_id/design/:design_id"
                                                render={() => <ProjectPage backend={this.state}/>}
                                            />
                                            <Route exact path="/library"><ContentLibrary backend={this.state}/></Route>
                                            <Route exact path="/templates"><ContentTemplates
                                                backend={this.state}/></Route>
                                            <Route component={NotFound}/>
                                        </Switch>
                                    </Content>
                                    <Footer style={{textAlign: 'center'}}>
                                        DOT - Dynamic Optimisation Tool ©2018-2021 Performics
                                    </Footer>
                                </Space>
                            </Layout>

                            :

                            <Layout>
                                <Switch>
                                    <Route
                                        exact path="/"
                                        render={props => <Login {...props} backend={this.state}/>}
                                    />
                                    <Redirect to={"/"}/>
                                </Switch>
                            </Layout>
                    }
                </Layout>
            </Router>
            :
            <></>
    }
}

export function ContentBreadcrumb(props) {
    return (
        <Breadcrumb style={{margin: '16px 0'}}>
            <Breadcrumb.Item>DOT</Breadcrumb.Item>
            <Breadcrumb.Item>{props.current}</Breadcrumb.Item>
        </Breadcrumb>
    );
}

class ProjectsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // Behaviour object
            actionFrom: {},

            // Data
            currency: "$",
            projectsLoaded: false,
            projects: [],
            errors: [],

            // For Projects Header
            headerType: "header-projects-content",
            headerBreadcrumbIndex: "DOT",
            headerBreadcrumbFirst: "Projects",
            headerBreadcrumbSecond: undefined,
            headerTitle: "Projects",
            headerExtra: [],
        };
        this.handleDeleteProject = this.handleDeleteProject.bind(this);
        this.handleRenameProject = this.handleRenameProject.bind(this);
        this.handleLoadProjects = this.handleLoadProjects.bind(this);
    }

    onChange(e) {
        e.preventDefault();
        const inputName = e.target.name;
        const inputValue = e.target.value;
        this.setState({
            [inputName]: inputValue,
        })
    }

    handleLoadProjects(force = false) {
        if (this.state.projectsLoaded === false || force === true) {
            this.props.backend.fetchApi('/projects/').then(
                result => {
                    this.setState(
                        {
                            projects: result.sort((a, b) => (a.created > b.created) ? -1 : 1)
                        },
                        () => this.setState({projectsLoaded: true})
                    )
                },
                err => {
                    this.setState({
                            errors: [
                                ...this.state.errors,
                                err.message
                            ]
                        },
                        () => {
                            alert(err)
                        });
                }
            );
        }
    }

    handleRenameProject(newText, projectId) {
        let newList;
        const key = "message-rename-project";

        message.loading({
            content: `Renaming project...`,
            key: key,
            duration: 3
        });
        this.props.backend.fetchApi(
            `/projects/${projectId}/`,
            {
                method: "PATCH",
                body: JSON.stringify({
                    name: newText
                })
            }
        ).then(
            () => {
                message.success(
                    {
                        content: `This project has been renamed!`,
                        key: key,
                        duration: 3
                    }
                );
                // eslint-disable-next-line array-callback-return
                newList = this.state.projects.map(project => {
                    if (project.id === projectId) {
                        project.name = newText;
                    }
                });
                this.setState({
                    projects: newList
                });
            },
            () => {
                message.error({
                    content: "An error has been occurred, sorry. Try it later.",
                    key: key,
                    duration: 3
                });
            }
        );
    }

    handleDeleteProject(projectId) {
        let projectName;
        let newList;
        const key = "delete-project-message";


        message.loading({
            content: `Deleting project...`,
            key: key,
            duration: 3
        });
        this.props.backend.fetchApi(`/projects/${projectId}/`, {method: "DELETE"}).then(
            () => {
                newList = this.state.projects.filter(project => {
                    if (project.id === projectId) {
                        projectName = project.name;
                        return false;
                    }
                    return true;
                });
                message.success(
                    {
                        content: `The project "${projectName}" has been deleted!`,
                        key: key,
                        duration: 3
                    }
                );
                this.setState({
                    projects: newList
                });
            },
            () => {
                message.error({
                    content: "An error has been occurred, sorry. Try it later.",
                    key: key,
                    duration: 3
                });
            }
        );
    }

    handleProjectsHeaderUpdate = (data) => {
        this.setState({
            headerType: data.headerType,
            headerBreadcrumbIndex: data.headerBreadcrumbIndex,
            headerBreadcrumbFirst: data.headerBreadcrumbFirst,
            headerBreadcrumbSecond: data.headerBreadcrumbSecond,
            headerTitle: data.headerTitle,
            headerExtra: data.headerExtra,
            actionFrom: data.actionFrom
        });
        // this.forceUpdate();
    }

    componentDidMount() {
        this.handleLoadProjects();
        this.props.backend.handleSwitchMenuSelect('menu-projects');
    }

    render() {

        const routes = [
            {breadcrumbName: this.state.headerBreadcrumbIndex},
            {breadcrumbName: this.state.headerBreadcrumbFirst}
        ];
        if (this.state.headerBreadcrumbSecond !== undefined) {
            routes.push({breadcrumbName: this.state.headerBreadcrumbSecond})
        }
        const headerData = {
            routes: {routes},
            title: this.state.headerTitle,
            extra: this.state.headerExtra
        };

        return (
            <>
                {
                    this.state.projectsLoaded ?
                        <Space direction="vertical" size="middle" style={{width: "100%"}}>

                            <ProjectsHeader
                                data={headerData}
                            />

                            <ProjectsContent
                                {...this.props}
                                projects={this.state.projects}
                                headerType={this.state.headerType}
                                handleHeaderUpdate={this.handleProjectsHeaderUpdate}
                                handleDeleteProject={this.handleDeleteProject}
                                handleRenameProject={this.handleRenameProject}
                                currency={this.state.currency}
                                fetch={this.props.backend.fetchApi}
                                handleLoadProjects={this.handleLoadProjects}
                                actionFrom={this.state.actionFrom}
                            />

                        </Space>
                        :
                        <div style={{textAlign: "center", marginTop: "100px"}}>
                            <Spin size="large"/>
                        </div>
                }
            </>
        );
    }
}

class ContentLibrary extends React.Component {

    componentDidMount() {
        this.props.backend.handleSwitchMenuSelect('menu-library');
    }

    render() {
        return (
            <>
                <ContentBreadcrumb current="Library"/>
                <h1>Library</h1>
            </>
        );
    }
}

class ContentTemplates extends Component {

    componentDidMount() {
        this.props.backend.handleSwitchMenuSelect('menu-templates');
    }

    render() {
        return (
            <>
                <ContentBreadcrumb current="Templates"/>
                <h1>Templates via API</h1>
            </>
        );
    }
}

ReactDOM.render(<Index/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
