import React from 'react';
import {Transformer} from 'react-konva';


export function enableTransform(app, e) {
    let target = e.target;
    const stage = target.getStage();

    if (target.name().indexOf('no-transform') >= 0) {
        target = target.findAncestor("Group")
    }

    if (target.getAttr('layer_id') !== undefined) {
        app.setState({
            selectedShapeName: target.name(),
            selectedLayer: target.getLayer().name()
        });
    } else {
        if (stage.find('Transformer').length) {
            app.setState({
                selectedShapeName: '',
                selectedLayer: ''
            });
        }
    }
}

export function selectLayer(app, layer_num) {
    let target = app.state.main_stage.findOne('.layer-' + layer_num.toString()).findOne('Group');

    app.setState({
        selectedShapeName: target.name(),
        selectedLayer: target.getLayer().name()
    });
}

export function removeTransform(app, el) {
    if (app.state.selectedShapeName || app.state.selectedShapeName) {
        app.setState({
            selectedShapeName: '',
            selectedLayer: ''
        });
    }
    return el && el.name() === app.state.selectedShapeName && el.getLayer().name() === app.state.selectedLayer;
}


class TransformerHandler extends React.Component {
    componentDidMount() {
        this.checkNode();
    }

    componentDidUpdate() {
        this.checkNode();
    }

    checkNode() {
        const stage = this.transformer.getStage();
        const {selectedShapeName} = this.props;
        const selectedNode = stage.findOne("." + selectedShapeName);
        if (selectedNode) {
            this.transformer.attachTo(selectedNode);
        } else {
            this.transformer.detach();
        }
        this.transformer.getLayer().batchDraw();
    }

    render() {
        return (
            <Transformer
                ref={node => {
                    this.transformer = node;
                }}
                keepRatio={this.props.keepRatio}
                name={'tf'}
            />
        );
    }
}

export default TransformerHandler;
