import React, {Component} from 'react';
import {Button, Col, Form, Input, Row} from 'antd';

export default class LoginUser extends Component {

    onFinish = (values) => {
        this.props.backend.logIn({
            username: values.username,
            password: values.password,
        });
    };

    render() {
        return (
            <>
                {
                    !this.props.backend.user &&
                    <Row>
                        <Col
                            style={{padding: "2rem"}}
                            sm={{offset: 2, span: 20}}
                            md={{offset: 3, span: 18}}
                            lg={{offset: 4, span: 16}}
                            xl={{offset: 5, span: 14}}
                            xxl={{offset: 6, span: 12}}
                        >

                            <Form
                                name="basic"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={this.onFinish}
                            >
                                <Form.Item
                                    label="Username"
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your username!',
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                    ]}
                                >
                                    <Input.Password/>
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>

                        </Col>
                    </Row>
                }
            </>
        )
    }
}
