import React, {Component} from 'react';
import {Layer, Rect, Shape, Stage, Line} from 'react-konva';
import {Col} from 'react-bootstrap'
import {GlobalContext} from '../../contexts/GlobalContext.jsx';
import TransformerHandler from './Transformer.jsx'
import {scaleText, getTextComponent} from './TextAdaptive.jsx';
import {scaleImage, getImageComponent} from "./ImageFromUrl.jsx";
import {findWithAttr, headerHeight, updateGroupPosition} from "./ProjectPage";
import KonvaControls from "./KonvaControls.jsx"


export function downloadStage(app) {
    app.removeTransform();
    const json_data = app.state.main_stage.toJSON();
    console.log('downloadStage', json_data);
    const projectId = app.props.match.params.project_id;

    const url = app.state.designId === undefined ?
        `/projects/${projectId}/designs/`
        :
        `/projects/${projectId}/designs/${app.state.designId}/`;
    app.api.fetchApi(url,
        {
            method: app.state.designId === undefined ? 'POST' : 'PUT',
            body: JSON.stringify({
                "name": app.state.designName,
                "design_json": json_data
            })
        }
    ).then(
        () => {
            alert("Saved!");
            if (app.state.designId === undefined) {
                window.location = `/projects`
            }
        },
        e => alert(e)
    )
    // :
    // app.api.fetchApi(`/projects/${projectId}/`,{
    //     method: 'PATCH',
    //     body: JSON.stringify({
    //         "name": app.state.projectName,
    //         "design_json": json_data
    //     })
    // }).catch(e=>alert(e));
}

export function importStage(app, json_data, parse = true) {
    let projectData = parse ? JSON.parse(json_data) : json_data;

    const canvasSizeAttrs = projectData.children.shift().children[0].attrs;
    app.setState({
        canvasSize: {
            width: canvasSizeAttrs.width,
            height: canvasSizeAttrs.height
        },
        defaultX: canvasSizeAttrs.x,
        defaultY: canvasSizeAttrs.y
    });

    let layers_from_stage_json = projectData.children;
    let new_layers = [];

    for (let layer of layers_from_stage_json) {

        // Group, Rect, Text attrs
        let group = layer.children[0];
        let shapes = group.children;
        let target_rect, target_rect_substrate, target_main, current_type;

        const layer_id = group.attrs.layer_id;
        let bgFill = "rgba(0,0,0,0)";

        let l = {
            id: layer_id,
            key_id: layer_id
        };
        if (shapes) {
            for (let shape of shapes) {
                if (shape.attrs.name === "no-transform") {
                    target_rect = shape
                } else if (shape.attrs.name === "no-transform-substrate") {
                    current_type = 'text';
                    target_rect_substrate = shape;
                } else if (shape.className === "Text") {
                    current_type = 'text';
                    target_main = shape;
                } else {
                    current_type = 'image';
                    target_main = shape
                }
            }

            const dynamic = target_main.attrs.dynamic;
            const x = group.attrs.x;
            const y = group.attrs.y;
            const width = target_rect.attrs.width;
            const height = target_rect.attrs.height;
            const rotation = group.attrs.rotation;
            const opacity = group.attrs.saved_opacity;
            bgFill = target_rect.attrs.fill;

            if (current_type === 'image') {
                const url = target_main.attrs.image_src;
                const mode = target_main.attrs.mode;
                const saved_fill = target_rect.attrs.saved_fill;

                l.component = () => getImageComponent(
                    app.state.feed,
                    dynamic,
                    dynamic || url,
                    app.state.currentFeedProduct,
                    app.state.enableTransform,
                    app.state.handleDragEnd,
                    app.state.handleTransformEnd,
                    app.state.removeTransform,
                    app.state.selectLayer,
                    app.state.getKonvaObjectSize,
                    layer_id,
                    x,
                    y,
                    width,
                    height,
                    rotation,
                    mode,
                    saved_fill,
                    opacity
                );
                l.content =
                    `Layer ${layer_id + 1} (Image)`
                ;
                l.x = x;
                l.y = y;
                l.rotation = rotation;
                l.type = 'Image';
                l.dynamic = dynamic;
                l.url = url;

            } else {  // text
                const text = target_main.attrs.text;
                const fill = target_main.attrs.fill;
                const minFontSize = target_main.attrs.minFontSize;
                const maxFontSize = target_main.attrs.maxFontSize;
                const maxLines = target_main.attrs.maxLines;
                const align = target_main.attrs.align || 'left';
                const verticalAlign = target_main.attrs.verticalAlign || 'top';
                const fontFamily = target_main.attrs.fontFamily || "Arial";
                const textDecoration = target_main.attrs.textDecoration;
                const fontStyle = target_main.attrs.fontStyle || "normal";
                const fontVariant = target_main.attrs.fontVariant || "normal";
                const opacity = target_main.attrs.opacity || 1;
                const cutText = target_main.attrs.cutText || false;

                const regexpMatch = target_main.attrs.regexpMatch;
                const regexpReplace = target_main.attrs.regexpReplace;
                const placeholderStatus = target_main.attrs.placeholderStatus;
                const placeholderText = target_main.attrs.placeholderText;

                const perfectToggle = target_rect_substrate.attrs.perfectToggle;
                const substrateToggle = target_rect_substrate.attrs.substrateToggle;
                const substrateFill = target_rect_substrate.attrs.saved_fill;
                const substrateTop = target_rect_substrate.attrs.substrateTop;
                const substrateRight = target_rect_substrate.attrs.substrateRight;
                const substrateBottom = target_rect_substrate.attrs.substrateBottom;
                const substrateLeft = target_rect_substrate.attrs.substrateLeft;
                const substrateCornerRadiusTL = target_rect_substrate.attrs.cornerRadius[0];
                const substrateCornerRadiusTR = target_rect_substrate.attrs.cornerRadius[1];
                const substrateCornerRadiusBR = target_rect_substrate.attrs.cornerRadius[2];
                const substrateCornerRadiusBL = target_rect_substrate.attrs.cornerRadius[3];

                l.component = () => getTextComponent(
                    app.state.feed,
                    dynamic,
                    dynamic || text,
                    app.state.currentFeedProduct,
                    app.state.enableTransform,
                    app.state.handleDragEnd,
                    app.state.handleTransformEnd,
                    app.state.removeTransform,
                    app.state.selectLayer,
                    app.state.getKonvaObjectSize,
                    layer_id,
                    x,
                    y,
                    width,
                    height,
                    rotation || 0,
                    fill,
                    bgFill,
                    align,
                    verticalAlign,
                    fontFamily,
                    textDecoration,
                    fontStyle,
                    fontVariant,
                    opacity,
                    minFontSize,
                    maxFontSize,
                    maxLines,
                    cutText,
                    regexpMatch,
                    regexpReplace,
                    placeholderStatus,
                    placeholderText,
                    perfectToggle,
                    substrateToggle,
                    substrateFill,
                    substrateTop,
                    substrateRight,
                    substrateBottom,
                    substrateLeft,
                    substrateCornerRadiusTL,
                    substrateCornerRadiusTR,
                    substrateCornerRadiusBR,
                    substrateCornerRadiusBL
                );
                l.content =
                    `Layer ${layer_id + 1} (Text)`
                ;
                l.x = x;
                l.y = y;
                l.rotation = rotation;
                l.type = 'Text';
                l.dynamic = dynamic;
                l.text = text;
            }

            new_layers.push(l);

        } else {
            console.log("Can't load empty layer: ", layer)
        }
    }

    app.setState({
        layers: new_layers
    });

    const newPos = {
        x: (app.state.main_stage.width() - app.state.main_stage.width() * app.state.main_stage.scaleX()) / 2,
        y: (app.state.main_stage.height() - app.state.main_stage.height() * app.state.main_stage.scaleY()) / 2
    };
    app.state.main_stage.position(newPos);

    app.state.main_stage.draw();

}

export function handleCustomCanvasSize(app, e) {
    e.persist();
    const target = e.target;
    const canvasWidth = target.name === "customCanvasSizeWidth" ? Number.parseInt(target.value) : app.state.canvasSize.width;
    const canvasHeight = target.name === "customCanvasSizeWidth" ? app.state.canvasSize.height : Number.parseInt(target.value);
    app.setState({
        canvasSize: {
            width: canvasWidth,
            height: canvasHeight,
        },
        defaultX: app.state.konvaWidth / 2 - canvasWidth / 2,
        defaultY: (window.innerHeight - headerHeight) / 2 - canvasHeight / 2,
        canvasSizesSelectedOption: 0,
    })
}

export function selectCanvasSize(app, selectedOptionNumber) {
    app.setState({
        canvasSizesSelectedOption: +selectedOptionNumber
    })
}

export function toggleOutlineArea(app, v) {
    app.setState({
        outlineVisiblity: v,
    })
}

export function changeCanvasSize(app, e) {
    e.persist();
    const canvasWidth = Number.parseInt(e.target.dataset.x);
    const canvasHeight = Number.parseInt(e.target.dataset.y);
    app.setState({
        canvasSize: {
            width: canvasWidth,
            height: canvasHeight
        },
        defaultX: app.state.konvaWidth / 2 - canvasWidth / 2,
        defaultY: (window.innerHeight - headerHeight) / 2 - canvasHeight / 2
    });
}

export function getKonvaObjectSize(app, obj) {
    const width = parseFloat(obj.width());
    const height = parseFloat(obj.height());
    const layer_index = findWithAttr(app.state.layers, 'id', obj.getAttr('layer_id'));
    app.state.layers[layer_index].width = width;
    app.state.layers[layer_index].height = height;
    return [width, height]
}


export function handleDragEnd(app, e) {
    const layer_id = e.target.getAttr('layer_id');
    const layer_index = findWithAttr(app.state.layers, 'id', layer_id);
    app.state.layers[layer_index].x = e.target.x();
    app.state.layers[layer_index].y = e.target.y();
    if (app.state.right_menu) {
        app.state.right_menu.forceUpdate();
    }
    updateGroupPosition(e.target);
}

export function handleTransformEnd(app, e) {
    const layer_id = e.target.getAttr('layer_id');

    let width = e.target.width() * e.target.scaleX();
    let height = e.target.height() * e.target.scaleY();

    if (e.target.getType().toString() === 'Group') {

        if (e.target.findOne('Text')) {
            let textObj = e.target.findOne('Text');
            scaleText(textObj).then(function () {
                if (app.state.right_menu) {
                    app.state.right_menu.forceUpdate();
                }
            })
        }

        if (e.target.findOne('Image')) {
            let imgRect = e.target.findOne('Rect');
            let imgObj = e.target.findOne('Image');
            imgRect.width(imgRect.width() * e.target.scaleX());
            imgRect.height(imgRect.height() * e.target.scaleY());

            scaleImage(imgObj, imgRect);

            e.target.scaleX(1);
            e.target.scaleY(1);
        }


        e.target.getLayer().batchDraw();
        updateGroupPosition(e.target);
    }
    const layer_index = findWithAttr(app.state.layers, 'id', layer_id);
    app.state.layers[layer_index].x = e.target.x();
    app.state.layers[layer_index].y = e.target.y();
    app.state.layers[layer_index].rotation = e.target.rotation();
    app.state.layers[layer_index].width = width;
    app.state.layers[layer_index].height = height;
    if (app.state.right_menu) {
        app.state.right_menu.forceUpdate();
    }
}

class KonvaApp extends Component {
    api = this.props.backend;
    state = {};
    GUIDELINE_OFFSET = 5;


    // were can we snap our objects?
    getLineGuideStops = (layer, skipShape) => {
        // we can snap to stage borders and the center of the stage
        var vertical = [
            this.state.konvaWidth / 2 - this.context.canvasSize.width / 2,
            this.state.konvaWidth / 2,
            this.state.konvaWidth / 2 + this.context.canvasSize.width / 2
        ];
        var horizontal = [
            this.state.konvaHeight / 2 - this.context.canvasSize.height / 2,
            this.state.konvaHeight / 2,
            this.state.konvaHeight / 2 + this.context.canvasSize.height / 2
        ];

        // and we snap over edges and center of each object on the canvas
        this.context.main_stage.find('Group').forEach(guideItem => {
            if (guideItem === skipShape || guideItem.name() === 'tf') {
                return;
            }
            const box = guideItem.getClientRect({relativeTo: guideItem});
            // and we can snap to all edges of shapes
            vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
            horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
        });
        return {
            vertical: vertical.flat(),
            horizontal: horizontal.flat()
        };
    };

    // what points of the object will trigger to snapping?
    // it can be just center of the object
    // but we will enable all edges and center
    getObjectSnappingEdges = (layer, node) => {
        var box = node.getClientRect({relativeTo: layer});
        return {
            vertical: [
                {
                    guide: Math.round(box.x),
                    offset: Math.round(node.x() - box.x),
                    snap: 'start'
                },
                {
                    guide: Math.round(box.x + box.width / 2),
                    offset: Math.round(node.x() - box.x - box.width / 2),
                    snap: 'center'
                },
                {
                    guide: Math.round(box.x + box.width),
                    offset: Math.round(node.x() - box.x - box.width),
                    snap: 'end'
                }
            ],
            horizontal: [
                {
                    guide: Math.round(box.y),
                    offset: Math.round(node.y() - box.y),
                    snap: 'start'
                },
                {
                    guide: Math.round(box.y + box.height / 2),
                    offset: Math.round(node.y() - box.y - box.height / 2),
                    snap: 'center'
                },
                {
                    guide: Math.round(box.y + box.height),
                    offset: Math.round(node.y() - box.y - box.height),
                    snap: 'end'
                }
            ]
        };
    };

    // find all snapping possibilities
    getGuides = (lineGuideStops, itemBounds) => {
        var resultV = [];
        var resultH = [];

        lineGuideStops.vertical.forEach(lineGuide => {
            itemBounds.vertical.forEach(itemBound => {
                var diff = Math.abs(lineGuide - itemBound.guide);
                // if the distance between guild line and object snap point is close we can consider this for snapping
                if (diff < this.GUIDELINE_OFFSET) {
                    resultV.push({
                        lineGuide: lineGuide,
                        diff: diff,
                        snap: itemBound.snap,
                        offset: itemBound.offset
                    });
                }
            });
        });

        lineGuideStops.horizontal.forEach(lineGuide => {
            itemBounds.horizontal.forEach(itemBound => {
                var diff = Math.abs(lineGuide - itemBound.guide);
                if (diff < this.GUIDELINE_OFFSET) {
                    resultH.push({
                        lineGuide: lineGuide,
                        diff: diff,
                        snap: itemBound.snap,
                        offset: itemBound.offset
                    });
                }
            });
        });

        var guides = [];

        // find closest snap
        var minV = resultV.sort((a, b) => a.diff - b.diff)[0];
        var minH = resultH.sort((a, b) => a.diff - b.diff)[0];
        if (minV) {
            guides.push({
                lineGuide: minV.lineGuide,
                offset: minV.offset,
                orientation: 'V',
                snap: minV.snap
            });
        }
        if (minH) {
            guides.push({
                lineGuide: minH.lineGuide,
                offset: minH.offset,
                orientation: 'H',
                snap: minH.snap
            });
        }
        return guides;
    };

    drawGuides = (layer, guides) => {

        guides.forEach(lg => {
            let line;
            if (lg.orientation === 'H') {
                line = (key) => <Line
                    key={key}
                    points={[-6000, lg.lineGuide, 6000, lg.lineGuide]}
                    stroke={'rgb(0, 161, 255)'}
                    strokeWidth={1}
                    name={'guid-line'}
                    dash={[4, 6]}
                />;
                if (this.state.snapper) {
                    let snappers = [...this.state.snapper];
                    snappers.push(line);
                    this.setState({
                        snapLayer: layer.name(),
                        snapper: snappers
                    });
                } else {
                    this.setState({
                        snapLayer: layer.name(),
                        snapper: [line]
                    });
                }
            } else if (lg.orientation === 'V') {
                line = (key) => <Line
                    key={key}
                    points={[lg.lineGuide, -6000, lg.lineGuide, 6000]}
                    stroke={'rgb(0, 161, 255)'}
                    strokeWidth={1}
                    name={'guid-line'}
                    dash={[4, 6]}
                />;
                if (this.state.snapper) {
                    let snappers = [...this.state.snapper];
                    snappers.push(line);
                    this.setState({
                        snapLayer: layer.name(),
                        snapper: snappers
                    });
                } else {
                    this.setState({
                        snapLayer: layer.name(),
                        snapper: [line]
                    });
                }
            }
        });
    };

    dragMove = (e) => {

        const layer = e.target.getLayer();
        // clear all previous lines on the screen
        this.setState({
            snapLayer: undefined,
            snapper: undefined
        });

        // find possible snapping lines
        var lineGuideStops = this.getLineGuideStops(layer, e.target);
        // find snapping points of current object
        var itemBounds = this.getObjectSnappingEdges(layer, e.target);

        // now find where can we snap current object
        var guides = this.getGuides(lineGuideStops, itemBounds);

        // do nothing of no snapping
        if (!guides.length) {
            return;
        }

        this.drawGuides(layer, guides);

        // now force object position
        guides.forEach(lg => {
            switch (lg.snap) {
                case 'start': {
                    switch (lg.orientation) {
                        case 'V': {
                            e.target.x(lg.lineGuide + lg.offset);
                            break;
                        }
                        case 'H': {
                            e.target.y(lg.lineGuide + lg.offset);
                            break;
                        }
                        default: {
                            break
                        }
                    }
                    break;
                }
                case 'center': {
                    switch (lg.orientation) {
                        case 'V': {
                            e.target.x(lg.lineGuide + lg.offset);
                            break;
                        }
                        case 'H': {
                            e.target.y(lg.lineGuide + lg.offset);
                            break;
                        }
                        default: {
                            break
                        }
                    }
                    break;
                }
                case 'end': {
                    switch (lg.orientation) {
                        case 'V': {
                            e.target.x(lg.lineGuide + lg.offset);
                            break;
                        }
                        case 'H': {
                            e.target.y(lg.lineGuide + lg.offset);
                            break;
                        }
                        default: {
                            break
                        }
                    }
                    break;
                }
                default: {
                    break
                }
            }
        });
    };

    componentDidMount() {
        this.setState({
            konvaWidth: this.element.offsetWidth,
            konvaHeight: window.innerHeight - this.props.headerHeight
        });
        this.context.konvaWidth = this.element.offsetWidth;
        this.context.main_stage = this.stageRef;
        this.context.defaultX = this.element.offsetWidth / 2 - this.context.canvasSize.width / 2;
        this.context.defaultY = (window.innerHeight - this.props.headerHeight) / 2 - this.context.canvasSize.height / 2;
    }

    render() {
        return <Col ref={(element) => {
            this.element = element
        }} xs={6} className='no-padding' style={{backgroundColor: "rgba(1,1,1,0.3)"}}>
            <Stage
                draggable
                width={this.state.konvaWidth}
                height={this.state.konvaHeight}
                ref={ref => {
                    this.stageRef = ref;
                }}
                onClick={this.context.enableTransform}
                onWheel={this.context.scrollStageZoom}
            >

                {/*Draw contents only when Stage is rendered*/}

                {this.state.konvaWidth &&

                /* Canvas size */
                <Layer name="canvasSize">
                    <Rect
                        x={this.context.defaultX}
                        y={this.context.defaultY}
                        width={this.context.canvasSize.width}  // Canvas size X
                        height={this.context.canvasSize.height}   // Canvas size Y
                        stroke="gray"
                        strokeWidth={1}
                        opacity={1}
                        fill={'white'}
                        ref={(element) => {
                            this.real_canvas = element
                        }}
                    />
                </Layer>
                }

                {this.state.konvaWidth &&
                this.context.layers.map((layer, i) => (

                    <Layer key={'layer-' + layer.key_id} name={'layer-' + layer.id}
                           drawBorder
                           clip={!this.context.outlineVisiblity ? {
                               x: this.context.defaultX,
                               y: this.context.defaultY,
                               width: this.context.canvasSize.width,  // Canvas size X
                               height: this.context.canvasSize.height  // Canvas size Y
                           } : {x: undefined, y: undefined, width: undefined, height: undefined}}
                           onDragMove={this.dragMove}
                           onDragEnd={(e) => {
                               const layer = e.target.getLayer();
                               layer.find('.guid-line').destroy();
                               layer.batchDraw();
                           }}
                    >
                        {layer.component()}
                        {this.context.selectedLayer === 'layer-' + layer.id &&
                        <TransformerHandler keepRatio={false} selectedShapeName={this.context.selectedShapeName}/>}
                        {this.state.snapLayer === 'layer-' + layer.id &&
                        this.state.snapper.map((snapper, i) => (snapper(i)))}
                    </Layer>
                ))
                }

                {/* Outline area */}
                {this.state.konvaWidth &&
                this.context.outlineVisiblity &&
                <Layer>
                    <Shape x={this.context.defaultX}
                           y={this.context.defaultY}
                           width={this.context.canvasSize.width} height={this.context.canvasSize.height}
                           fill="rgba(0,0,0,0)"
                           sceneFunc={(ctx, shape) => {
                               ctx.beginPath();
                               const bord = 100000;
                               ctx.moveTo(-bord, -bord);
                               ctx.lineTo(shape.width() + bord, -bord);
                               ctx.lineTo(shape.width() + bord, shape.height() + bord);
                               ctx.lineTo(-bord, shape.height() + bord);
                               ctx.lineTo(-bord, shape.height());
                               ctx.lineTo(shape.width(), shape.height());
                               ctx.lineTo(shape.width(), 0);
                               ctx.lineTo(0, 0);
                               ctx.lineTo(0, shape.height());
                               ctx.lineTo(-bord, shape.height());
                               ctx.lineTo(-bord, -bord);
                               ctx.closePath();
                               ctx.fillStrokeShape(shape);
                           }
                           }
                        // remove shape from hit graph, so it is not visible for events
                           listening={false}
                    >
                    </Shape>
                </Layer>
                }

            </Stage>

            {/* Zoom Stage */}
            <KonvaControls/>

        </Col>

    }
}

KonvaApp.contextType = GlobalContext;
export default KonvaApp;
