import React, {Component} from 'react';
import {GlobalContext} from '../../contexts/GlobalContext.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowLeft, faArrowRight, faSearchPlus, faSearchMinus} from '@fortawesome/free-solid-svg-icons'


export function changeProduct(app, direction) {
    if (direction === "+") {
        if (app.state.currentFeedProduct < app.state.feed.length - 1) {
            app.setState({currentFeedProduct: app.state.currentFeedProduct + 1})
        } else {
            app.setState({currentFeedProduct: 0})
        }
    } else {
        if (app.state.currentFeedProduct !== 0) {
            app.setState({currentFeedProduct: app.state.currentFeedProduct - 1})
        } else {
            app.setState({currentFeedProduct: app.state.feed.length - 1})
        }
    }
    app.state.konva_controls.forceUpdate();
}

export function changeStageZoom(app, zoomDirection) {
    const scaleBy = 1.15;

    const newScale  = {
        x: (zoomDirection === "+") ?
            app.state.main_stage.scaleX() * scaleBy : app.state.main_stage.scaleX() / scaleBy,
        y: (zoomDirection === "+") ?
            app.state.main_stage.scaleY() * scaleBy : app.state.main_stage.scaleY() / scaleBy,
    };
    app.state.main_stage.scale(newScale);
    const newPos = {
          x: (app.state.main_stage.width() - app.state.main_stage.width() * newScale.x) / 2,
          y: (app.state.main_stage.height() - app.state.main_stage.height() * newScale.y) /2
    };
    app.state.main_stage.position(newPos);
    app.state.main_stage.batchDraw();
    app.state.konva_controls.forceUpdate();
}

export function scrollStageZoom(app, e) {
    const scaleBy = 1.01;

    e.evt.preventDefault();

    var oldScale = app.state.main_stage.scaleX();

    var mousePointTo = {
        x: app.state.main_stage.getPointerPosition().x / oldScale - app.state.main_stage.x() / oldScale,
        y: app.state.main_stage.getPointerPosition().y / oldScale - app.state.main_stage.y() / oldScale
    };

    var newScale =
        e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    app.state.main_stage.scale({x: newScale, y: newScale});

    var newPos = {
        x:
            -(mousePointTo.x - app.state.main_stage.getPointerPosition().x / newScale) *
            newScale,
        y:
            -(mousePointTo.y - app.state.main_stage.getPointerPosition().y / newScale) *
            newScale
    };
    app.state.main_stage.position(newPos);
    app.state.main_stage.batchDraw();
    app.state.konva_controls.forceUpdate();
}

class KonvaControls extends Component {

    componentDidMount() {
        this.context.konva_controls = this;
    }

    render() {
        return <div className="stage-control">
                <label className={"noselect"} onClick={() => this.context.changeProduct("-")}>
                    <FontAwesomeIcon icon={faArrowLeft} onClick={() => this.context.changeProduct("-")}/>
                </label>
                <label className={"noselect"}>{this.context.currentFeedProduct+1} / {this.context.feed.length}</label>
                <label className={"noselect"} onClick={() => this.context.changeProduct("+")}>
                    <FontAwesomeIcon icon={faArrowRight} onClick={() => this.context.changeProduct("+")}/>
                </label>
                <label className={"noselect"} onClick={() => this.context.zoomStage("-")}>
                    <FontAwesomeIcon icon={faSearchMinus}/>
                </label>
                <label className={"noselect"}>{Number(((this.context.main_stage ? this.context.main_stage.scaleX() : 0)*100).toFixed(0))}%</label>
                <label className={"noselect"} onClick={() => this.context.zoomStage("+")}>
                    <FontAwesomeIcon icon={faSearchPlus}/>
                </label>
            </div>

    }
}

KonvaControls.contextType = GlobalContext;
export default KonvaControls;
