import React from 'react';
import {Group, Rect, Text} from 'react-konva';
import Konva from 'konva';
import {Col, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {GlobalContext} from "../../contexts/GlobalContext.jsx";
import ColorPicker from "./ColorPicker.jsx";
import DropdownGenerator from "./Dropdown.jsx";
import {updateGroupPosition} from "./ProjectPage";
import FontFaceObserver from "fontfaceobserver";

export const fontFamilies = [
    "Arial Normal", "Arial Bold", "Arial Italic",
    "Calibri",
    "OpenSans Regular", "OpenSans Semibold", "OpenSans Bold",
    "ElectroluxSans Regular", "ElectroluxSans SemiBold", "SamsungSharp SansBd",
    "CorpidE3SCd 700", "CorpidE3SCd 500", "CirceRounded-Regular", "CirceRounded-Bold"
];
export const alignValues = ["Left", "Center", "Right"];
export const verticalAlignValues = ["Top", "Middle", "Bottom"];
export const textDecorationValues = ["None", "Underline", "Line-through", "Underline line-through"];
export const fontStyleValues = ["Normal", "Italic", "Bold"];
export const fontVariantValues = ["Normal", "Small-caps"];

const checkRegExp = regexp => {
    try {
        new RegExp(regexp)
    } catch {
        return false
    }
    return true
};

export function setTextSize(text_menu) {
    const target_group = text_menu.props.selectedShape.findAncestor('Group');
    const txtRect = target_group.findOne('.no-transform');
    const txtSubstrate = target_group.findOne('.no-transform-substrate');
    const txtText = target_group.findOne('Text');

    // Substrate
    if (text_menu.substrateToggle.checked) {
        txtSubstrate.setAttr('substrateTop', text_menu.substrateTopInput.value);
        txtSubstrate.setAttr('substrateRight', text_menu.substrateRightInput.value);
        txtSubstrate.setAttr('substrateBottom', text_menu.substrateBottomInput.value);
        txtSubstrate.setAttr('substrateLeft', text_menu.substrateLeftInput.value);
    }

    // Rectangle
    txtRect.width(parseFloat(text_menu.widthInput.value));
    txtRect.height(parseFloat(text_menu.heightInput.value));

    // Text
    txtText.setAttr('minFontSize', parseFloat(text_menu.minFontSizeInput.value));
    txtText.setAttr('maxFontSize', parseFloat(text_menu.maxFontSizeInput.value));
    txtText.setAttr('maxLines', parseFloat(text_menu.maxLinesInput.value));

    // Group
    target_group.rotation(parseFloat(text_menu.rotationInput.value));
    target_group.x(parseFloat(text_menu.xInput.value) + text_menu.context.defaultX);
    target_group.y(parseFloat(text_menu.yInput.value) + text_menu.context.defaultY);

    text_menu.context.removeTransform();
    scaleText(text_menu.props.selectedShape);
    text_menu.context.selectLayer(text_menu.props.selectedShape.getAttr('layer_id'));
    updateGroupPosition(target_group);
}

export function setTextOpacity(text_menu) {

    const target_group = text_menu.props.selectedShape.findAncestor('Group');
    const txtText = target_group.findOne('Text');
    txtText.opacity(parseFloat(text_menu.opacityInput.value));
    text_menu.forceUpdate();
    txtText.getLayer().batchDraw();
}

export class StaticTextSettings extends React.Component {

    setText = () => {
        this.props.selectedShape.setAttr('fullText', this.textInput.value);
        scaleText(this.props.selectedShape);
        this.forceUpdate();
    };

    componentDidMount() {
        this.context.right_menu = this;
    }

    componentWillUnmount() {
        this.context.right_menu = undefined;
    }

    render() {
        const txtGroup = this.props.selectedShape.findAncestor('Group');
        const txtRect = txtGroup.findOne('.no-transform');
        const txtText = txtGroup.findOne('Text');

        return (
            <Form onSubmit={(e) => {
                e.preventDefault()
            }}>
                <h2>Static Text Settings</h2>
                <Form.Group controlId="formStaticTextSize">
                    <Row>
                        <Col>
                            <InputGroup className="lg">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Text</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="text" name="setStaticText"
                                             ref={input => this.textInput = input}
                                             value={this.props.selectedShape.getAttr('fullText')}
                                             onChange={this.setText}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col className="mb-6">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>W</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="number" name="setStaticTextWidth"
                                             ref={input => this.widthInput = input}
                                             value={txtRect.width()}
                                             onChange={() => setTextSize(this)}
                                />
                            </InputGroup>
                        </Col>
                        <Col className="mb-6">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>H</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="number" name="setStaticTextHeight"
                                             ref={input => this.heightInput = input}
                                             value={txtRect.height()}
                                             onChange={() => setTextSize(this)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col className="mb-6">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>X</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="number" name="setStaticTextX"
                                             ref={input => this.xInput = input}
                                             value={txtGroup.x() - this.context.defaultX}
                                             onChange={() => setTextSize(this)}
                                />
                            </InputGroup>
                        </Col>
                        <Col className="mb-6">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Y</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="number" name="setStaticTextY"
                                             ref={input => this.yInput = input}
                                             value={txtGroup.y() - this.context.defaultY}
                                             onChange={() => setTextSize(this)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Text font</InputGroup.Text>
                        </InputGroup.Prepend>
                        <DropdownGenerator
                            id="dropdown-button-static-text-font"
                            obj={txtText}
                            objMethod="fontFamily"
                            data={fontFamilies}
                            className="form-control"
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>H Align</InputGroup.Text>
                        </InputGroup.Prepend>
                        <DropdownGenerator
                            id="dropdown-button-static-text-align"
                            obj={txtText}
                            objMethod="align" // horizontal
                            data={alignValues}
                            className="form-control"
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>V Align</InputGroup.Text>
                        </InputGroup.Prepend>
                        <DropdownGenerator
                            id="dropdown-button-static-text-align"
                            obj={txtText}
                            objMethod="verticalAlign"
                            data={verticalAlignValues}
                            className="form-control"
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Text Decoration</InputGroup.Text>
                        </InputGroup.Prepend>
                        <DropdownGenerator
                            id="dropdown-button-static-text-decoration"
                            obj={txtText}
                            objMethod="textDecoration"
                            data={textDecorationValues}
                            className="form-control"
                        />
                    </InputGroup>
                </Form.Group>

                {/*<Form.Group>*/}
                {/*    <InputGroup>*/}
                {/*        <InputGroup.Prepend>*/}
                {/*            <InputGroup.Text>Font Style</InputGroup.Text>*/}
                {/*        </InputGroup.Prepend>*/}
                {/*        <DropdownGenerator*/}
                {/*            id="dropdown-button-static-font-style"*/}
                {/*            obj={txtText}*/}
                {/*            objMethod="fontStyle"*/}
                {/*            data={fontStyleValues}*/}
                {/*            className="form-control"*/}
                {/*        />*/}
                {/*    </InputGroup>*/}
                {/*</Form.Group>*/}

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Font Variant</InputGroup.Text>
                        </InputGroup.Prepend>
                        <DropdownGenerator
                            id="dropdown-button-static-font-variant"
                            obj={txtText}
                            objMethod="fontVariant"
                            data={fontVariantValues}
                            className="form-control"
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Text color</InputGroup.Text>
                        </InputGroup.Prepend>
                        <ColorPicker obj={txtText}/>
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Bg color</InputGroup.Text>
                        </InputGroup.Prepend>
                        <ColorPicker obj={txtRect}/>
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Rotation</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="number" name="setStaticTextRotation"
                                     ref={input => this.rotationInput = input}
                                     value={txtGroup.rotation()}
                                     onChange={() => setTextSize(this)}
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Opacity</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="number" name="setStaticTextOpacity"
                                     min="0" max="1" step="0.01"
                                     ref={input => this.opacityInput = input}
                                     value={txtText.opacity()}
                                     onChange={() => setTextOpacity(this)}
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Min font size</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="number" name="setStaticTextMinFontSize"
                                     ref={input => this.minFontSizeInput = input}
                                     value={txtText.getAttr('minFontSize')}
                                     onChange={() => setTextSize(this)}
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Max font size</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="number" name="setStaticTextMaxFontSize"
                                     ref={input => this.maxFontSizeInput = input}
                                     value={txtText.getAttr('maxFontSize')}
                                     onChange={() => setTextSize(this)}
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Max lines</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="number" name="setStaticTextMaxLines"
                                     ref={input => this.maxLinesInput = input}
                                     value={txtText.getAttr('maxLines')}
                                     onChange={() => setTextSize(this)}
                        />
                    </InputGroup>
                </Form.Group>
            </Form>
        );
    }
}

export class DynamicTextSettings extends React.Component {

    setText = () => {
        if (!this.cutInput.checked && !window.confirm('Disabling cutting text will cause removing product with overflowed text from output feeds.\nAre you sure?')) {
            this.cutInput.checked = !this.cutInput.checked
        } else {
            const target = this.props.selectedShape;
            const target_group = this.props.selectedShape.findAncestor('Group');
            const target_rect = target_group.findOne('.no-transform');
            const target_substrate = target_group.findOne('.no-transform-substrate');

            // force set new data to update menu instantly
            target.setAttr('placeholderStatus', this.placeholderStatusInput.checked);
            target.setAttr('placeholderText', this.placeholderTextInput.value);
            this.props.selectedShape.setAttr('dynamic', this.textInput.value);
            target.setAttr('regexpMatch', this.regExpMatchInput.value);
            target.setAttr('regexpReplace', this.regExpReplaceInput.value);
            target.setAttr('cutText', this.cutInput.checked);
            target_substrate.setAttr('substrateToggle', this.substrateToggle.checked);
            target_substrate.setAttr('perfectToggle', this.perfectToggle ? this.perfectToggle.checked : target_substrate.getAttr('perfectToggle'));

            if (!this.substrateToggle.checked) {
                target_substrate.fill('rgba(0,0,0,0)')
            }
            const corners = [
                this.substrateTLInput ? this.substrateTLInput.value : target_substrate.getAttr("cornerRadius")[0],
                this.substrateTRInput ? this.substrateTRInput.value : target_substrate.getAttr("cornerRadius")[1],
                this.substrateBRInput ? this.substrateBRInput.value : target_substrate.getAttr("cornerRadius")[2],
                this.substrateBLInput ? this.substrateBLInput.value : target_substrate.getAttr("cornerRadius")[3]
            ];
            target_substrate.cornerRadius(corners);

            this.context.updateText(
                target.getAttr('layer_id'),
                this.textInput.value,
                target_group.x(),
                target_group.y(),
                target.width(),
                target.height(),
                target_group.rotation(),
                this.textInput.value,
                target.opacity(),
                target.fill(),
                target_rect.getAttr('saved_fill'),
                target.align(),
                target.verticalAlign(),
                target.fontFamily(),
                target.textDecoration(),
                target.fontStyle(),
                target.fontVariant(),
                target.getAttr('minFontSize'),
                target.getAttr('maxFontSize'),
                target.getAttr('maxLines'),
                this.cutInput.checked,
                this.regExpMatchInput.value,
                this.regExpReplaceInput.value,
                this.placeholderStatusInput.checked,
                this.placeholderTextInput.value,
                target_substrate.getAttr('perfectToggle'),
                this.substrateToggle.checked,
                target_substrate.getAttr('saved_fill'),
                this.substrateTopInput ? this.substrateTopInput.value : target_substrate.getAttr("substrateTop"),
                this.substrateRightInput ? this.substrateRightInput.value : target_substrate.getAttr("substrateRight"),
                this.substrateBottomInput ? this.substrateBottomInput.value : target_substrate.getAttr("substrateBottom"),
                this.substrateLeftInput ? this.substrateLeftInput.value : target_substrate.getAttr("substrateLeft"),
                corners[0],
                corners[1],
                corners[2],
                corners[3]
            );
        }
        this.forceUpdate();
    };

    componentDidMount() {
        this.context.right_menu = this;
    }

    componentWillUnmount() {
        this.context.right_menu = undefined;
    }

    render() {
        const txtGroup = this.props.selectedShape.findAncestor('Group');
        const txtRect = txtGroup.findOne('.no-transform');
        const txtText = txtGroup.findOne('Text');
        const txtSubstrate = txtGroup.findOne('.no-transform-substrate');
        const substrateCorners = txtSubstrate.cornerRadius();
        return (
            <Form>
                <h2>Dynamic Text Settings</h2>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="text-field-select">Select field:</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            as="select"
                            ref={input => this.textInput = input}
                            value={this.props.selectedShape.getAttr('dynamic')}
                            onChange={this.setText}
                        >
                            {Object.keys(this.context.feed[this.context.currentFeedProduct]).map((field, i) => (
                                <option key={'text-field-option-' + i}>{field}</option>
                            ))}
                        </Form.Control>
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <Form.Check
                        type="switch"
                        id="substrate-switch"
                        label='Toggle substrate'
                        checked={txtSubstrate.getAttr('substrateToggle')}
                        ref={input => this.substrateToggle = input}
                        onChange={this.setText}
                    />
                </Form.Group>
                {
                    txtSubstrate.getAttr('substrateToggle') &&
                    <>
                        <Form.Group>
                        <Form.Check
                            type="switch"
                            id="substrate-perfect-switch"
                            label='Toggle perfect mode'
                            checked={txtSubstrate.getAttr('perfectToggle')}
                            ref={input => this.perfectToggle = input}
                            onChange={this.setText}
                        />
                    </Form.Group>
                        <Form.Group>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Substrate color</InputGroup.Text>
                                </InputGroup.Prepend>
                                <ColorPicker obj={txtSubstrate}/>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group>
                            <Row>
                                <Col className="mb-6">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Top</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl type="number" name="setSubstrateTop"
                                                     ref={input => this.substrateTopInput = input}
                                                     value={txtSubstrate.getAttr('substrateTop')}
                                                     onChange={() => setTextSize(this)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col className="mb-6">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Right</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl type="number" name="setSubstrateRight"
                                                     ref={input => this.substrateRightInput = input}
                                                     value={txtSubstrate.getAttr('substrateRight')}
                                                     onChange={() => setTextSize(this)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row>
                                <Col className="mb-6">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Bottom</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl type="number" name="setSubstrateBottom"
                                                     ref={input => this.substrateBottomInput = input}
                                                     value={txtSubstrate.getAttr('substrateBottom')}
                                                     onChange={() => setTextSize(this)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col className="mb-6">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Left</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl type="number" name="setSubstrateLeft"
                                                     ref={input => this.substrateLeftInput = input}
                                                     value={txtSubstrate.getAttr('substrateLeft')}
                                                     onChange={() => setTextSize(this)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row>
                                <Col className="mb-6">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>TL</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl type="number" name="setSubstrateTLCorner"
                                                     ref={input => this.substrateTLInput = input}
                                                     value={substrateCorners[0]}
                                                     onChange={this.setText}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col className="mb-6">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>TR</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl type="number" name="setSubstrateTRCorner"
                                                     ref={input => this.substrateTRInput = input}
                                                     value={substrateCorners[1]}
                                                     onChange={this.setText}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row>
                                <Col className="mb-6">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>BL</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl type="number" name="setSubstrateBLCorner"
                                                     ref={input => this.substrateBLInput = input}
                                                     value={substrateCorners[3]}
                                                     onChange={this.setText}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col className="mb-6">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>BR</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl type="number" name="setSubstrateBRCorner"
                                                     ref={input => this.substrateBRInput = input}
                                                     value={substrateCorners[2]}
                                                     onChange={this.setText}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Form.Group>
                    </>
                }

                <Form.Group>
                    <Row>
                        <Col className="mb-6">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>W</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="number" name="setDynamicTextWidth"
                                             ref={input => this.widthInput = input}
                                             value={txtRect.width()}
                                             onChange={() => setTextSize(this)}
                                />
                            </InputGroup>
                        </Col>
                        <Col className="mb-6">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>H</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="number" name="setDynamicTextHeight"
                                             ref={input => this.heightInput = input}
                                             value={txtRect.height()}
                                             onChange={() => setTextSize(this)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col className="mb-6">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>X</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="number" name="setDynamicTextX"
                                             ref={input => this.xInput = input}
                                             value={txtGroup.x() - this.context.defaultX}
                                             onChange={() => setTextSize(this)}
                                />
                            </InputGroup>
                        </Col>
                        <Col className="mb-6">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Y</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="number" name="setDynamicTextY"
                                             ref={input => this.yInput = input}
                                             value={txtGroup.y() - this.context.defaultY}
                                             onChange={() => setTextSize(this)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Text font</InputGroup.Text>
                        </InputGroup.Prepend>
                        <DropdownGenerator
                            id="dropdown-button-dynamic-text-font"
                            obj={txtText}
                            objMethod="fontFamily"
                            data={fontFamilies}
                            className="form-control"
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>H Align</InputGroup.Text>
                        </InputGroup.Prepend>
                        <DropdownGenerator
                            id="dropdown-button-dynamic-text-align"
                            obj={txtText}
                            objMethod="align" // horizontal
                            data={alignValues}
                            className="form-control"
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>V Align</InputGroup.Text>
                        </InputGroup.Prepend>
                        <DropdownGenerator
                            id="dropdown-button-dynamic-text-align"
                            obj={txtText}
                            objMethod="verticalAlign"
                            data={verticalAlignValues}
                            className="form-control"
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Text Decoration</InputGroup.Text>
                        </InputGroup.Prepend>
                        <DropdownGenerator
                            id="dropdown-button-dynamic-text-decoration"
                            obj={txtText}
                            objMethod="textDecoration"
                            data={textDecorationValues}
                            className="form-control"
                        />
                    </InputGroup>
                </Form.Group>

                {/*<Form.Group>*/}
                {/*    <InputGroup>*/}
                {/*        <InputGroup.Prepend>*/}
                {/*            <InputGroup.Text>Font Style</InputGroup.Text>*/}
                {/*        </InputGroup.Prepend>*/}
                {/*        <DropdownGenerator*/}
                {/*            id="dropdown-button-dynamic-font-style"*/}
                {/*            obj={txtText}*/}
                {/*            objMethod="fontStyle"*/}
                {/*            data={fontStyleValues}*/}
                {/*            className="form-control"*/}
                {/*        />*/}
                {/*    </InputGroup>*/}
                {/*</Form.Group>*/}

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Font Variant</InputGroup.Text>
                        </InputGroup.Prepend>
                        <DropdownGenerator
                            id="dropdown-button-dynamic-font-variant"
                            obj={txtText}
                            objMethod="fontVariant"
                            data={fontVariantValues}
                            className="form-control"
                        />
                    </InputGroup>
                </Form.Group>


                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Text color</InputGroup.Text>
                        </InputGroup.Prepend>
                        <ColorPicker obj={txtText}/>
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Bg color</InputGroup.Text>
                        </InputGroup.Prepend>
                        <ColorPicker obj={txtRect}/>
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Rotation</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="number" name="setDynamicTextRotation"
                                     ref={input => this.rotationInput = input}
                                     value={txtGroup.rotation()}
                                     onChange={() => setTextSize(this)}
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Opacity</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="number" name="setDynamicTextOpacity"
                                     min="0" max="1" step="0.01"
                                     ref={input => this.opacityInput = input}
                                     value={txtText.opacity()}
                                     onChange={() => setTextOpacity(this)}
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Min font size</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="number" name="setDynamicTextMinFontSize"
                                     ref={input => this.minFontSizeInput = input}
                                     value={txtText.getAttr('minFontSize')}
                                     onChange={() => setTextSize(this)}
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Max font size</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="number" name="setDynamicTextMaxFontSize"
                                     ref={input => this.maxFontSizeInput = input}
                                     value={txtText.getAttr('maxFontSize')}
                                     onChange={() => setTextSize(this)}
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Max lines</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="number" name="setDynamicTextMaxLines"
                                     ref={input => this.maxLinesInput = input}
                                     value={txtText.getAttr('maxLines')}
                                     onChange={() => setTextSize(this)}
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Match</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="text" name="setRegExpMatch"
                                     ref={input => this.regExpMatchInput = input}
                                     value={txtText.getAttr('regexpMatch')}
                                     onChange={this.setText}
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Replace</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="text" name="setRegExpReplace"
                                     ref={input => this.regExpReplaceInput = input}
                                     value={txtText.getAttr('regexpReplace')}
                                     onChange={this.setText}
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <Form.Check
                        type="switch"
                        id="cutText-switch"
                        label='Cut overflowed text'
                        ref={input => this.cutInput = input}
                        checked={this.props.selectedShape.getAttr('cutText')}
                        onChange={this.setText}
                    />
                </Form.Group>

                <Form.Group>
                        <Form.Check
                            type="switch"
                            id="placeholder-switch"
                            label='Placeholder'
                            ref={input => this.placeholderStatusInput = input}
                            checked={txtText.getAttr('placeholderStatus')}
                            onChange={this.setText}
                        />
                        <FormControl type="text" name="setDynamicPlaceholderText"
                                     ref={input => this.placeholderTextInput = input}
                                     disabled={!txtText.getAttr('placeholderStatus')}
                                     value={txtText.getAttr('placeholderText')}
                                     onChange={this.setText}
                        />
                </Form.Group>
            </Form>

        )
            ;
    }
}


export function scaleText(textObj) {
    textObj.text(textObj.getAttr('fullText'));

    let txtGroup = textObj.findAncestor('Group'),
        substrate = txtGroup.findOne('.no-transform-substrate'),
        bgRect = txtGroup.findOne('.no-transform'),
        fontObserver = new FontFaceObserver(textObj.fontFamily()),
        vis_text, width, height;

    const minFontSize = textObj.getAttr('minFontSize'),
        maxFontSize = textObj.getAttr('maxFontSize'),
        maxLines = textObj.getAttr('maxLines'),
        substrateLeft = parseInt(substrate.getAttr('substrateLeft')),
        substrateRight = parseInt(substrate.getAttr('substrateRight')),
        substrateTop = parseInt(substrate.getAttr('substrateTop')),
        substrateBottom = parseInt(substrate.getAttr('substrateBottom')),
        substrateToggle = substrate.getAttr('substrateToggle'),
        perfectToggle = substrate.getAttr('perfectToggle'),
        align = textObj.align(),
        verticalAlign = textObj.getAttr('verticalAlign');

    return fontObserver.load().then(function () {

        width = bgRect.width() * txtGroup.scaleX();
        height = bgRect.height() * txtGroup.scaleY();

        bgRect.width(width);
        bgRect.height(height);

        if (substrateToggle) {
            textObj.width(width - substrateLeft - substrateRight);
            textObj.height(height - substrateTop - substrateBottom);
        } else {
            textObj.width(width);
            textObj.height(height);
        }
        textObj.position({x: 0, y: 0});

        textObj.setAttr('fontSize', maxFontSize);
        txtGroup.getLayer().batchDraw();

        vis_text = collectVisibleText(textObj);

        while (!checkText(vis_text, textObj) && textObj.fontSize() >= minFontSize) {
            textObj.fontSize(textObj.fontSize() - 1);
            txtGroup.getLayer().batchDraw();
            vis_text = collectVisibleText(textObj);
        }

        let realVisText = getRealVisibleTextForLines(textObj);

        if (!checkText(vis_text, textObj)) {
            if (textObj.getAttr('cutText')) {
                realVisText = realVisText.substring(0, realVisText.length - 3) + '...';
                textObj.text(realVisText);
                bgRect.fill(bgRect.getAttr('saved_fill'));
            } else {
                // TODO: raise if validating
                bgRect.fill('red');
            }
        } else {
            bgRect.fill(bgRect.getAttr('saved_fill'));
        }

        if (substrateToggle) {

            const textSize = getTextSize(textObj);

            substrate.position({
                x: perfectToggle && align === 'left' ? 0 : textSize.x,
                y: perfectToggle && verticalAlign === 'top' ? 0 : textSize.y
            });

            const substrateWidth = textSize.width + substrateLeft + substrateRight + (
                perfectToggle && align === 'left' ? textSize.x : 0
            );

            const substrateHeight = textSize.height + substrateTop + substrateBottom + (
                perfectToggle && verticalAlign === 'top' ? textSize.y : 0
            );

            substrate.size({
                width: perfectToggle && align === 'right' ? bgRect.width() - substrate.x() : substrateWidth,
                height: perfectToggle && verticalAlign === 'bottom' ? bgRect.height() - substrate.y() : substrateHeight
            });

            textObj.position({x: substrateLeft, y: substrateTop});

        } else {
            substrate.size({width: width, height: height});
            substrate.position({x: 0, y: 0});
        }

        txtGroup.scale({x: 1, y: 1});

    }).catch(function (e) {
        console.error('Error loading font', textObj.fontFamily(), e);
        throw e;
    });

    function getRealVisibleTextForLines(txtObj) {
        let visText = '',
            lineCnt = 0;
        for (let t of txtObj.textArr) {
            txtObj.text().indexOf(visText + t.text) >= 0 ?
                visText = visText + t.text
                :
                visText = visText + ' ' + t.text;
            lineCnt++;
            if (lineCnt === maxLines) {
                break
            }
        }
        return visText
    }


    // Trim Canvas Pixels Method
    // https://gist.github.com/remy/784508
    function getTextSize(obj) {
        let initObjectGroup = obj.findAncestor('Group');

        // Create a blank canvas (by not filling a background color).
        var container = document.createElement('div');
        container.setAttribute("id", "measuringCanvas");
        container.width = obj.width();
        container.height = obj.height();

        document.body.insertBefore(container, document.getElementById("react-beautiful-dnd-announcement-0"));

        var stage = new Konva.Stage({
            container: 'measuringCanvas',
            width: obj.width(),
            height: obj.height()
          });
        let layer = new Konva.Layer();

        layer.add(obj);

        stage.add(layer);

        stage.draw();

        let canvas = stage.toCanvas();

// Remove the surrounding transparent pixels
// result is an actual canvas element

        var ctx = canvas.getContext('2d'),

            // create a temporary canvas in which we will draw back the trimmed text
            copy_canvas = document.createElement('canvas'),
            copy = copy_canvas.getContext('2d'),

            // Use the Canvas Image Data API, in order to get all the
            // underlying pixels data of that canvas. This will basically
            // return an array (Uint8ClampedArray) containing the data in the
            // RGBA order. Every 4 items represent one pixel.
            pixels = ctx.getImageData(0, 0, canvas.width, canvas.height),

            // total pixels
            l = pixels.data.length,

            // main loop counter and pixels coordinates
            i, x, y,

            // an object that will store the area that isn't transparent
            bound = {top: null, left: null, right: null, bottom: null};

        // for every pixel in there
        for (i = 0; i < l; i += 4) {

            // if the alpha value isn't ZERO (transparent pixel)
            if (pixels.data[i + 3] !== 0) {

                // find it's coordinates
                x = (i / 4) % canvas.width;
                y = ~~((i / 4) / canvas.width);

                // store/update those coordinates
                // inside our bounding box Object

                if (bound.top === null) {
                    bound.top = y;
                }

                if (bound.left === null) {
                    bound.left = x;
                } else if (x < bound.left) {
                    bound.left = x;
                }

                if (bound.right === null) {
                    bound.right = x;
                } else if (bound.right < x) {
                    bound.right = x;
                }

                if (bound.bottom === null) {
                    bound.bottom = y;
                } else if (bound.bottom < y) {
                    bound.bottom = y;
                }
            }
        }

        // actual height and width of the text
        // (the zone that is actually filled with pixels)
        var trimHeight = bound.bottom - bound.top,
            trimWidth = bound.right - bound.left;

            // get the zone (trimWidth x trimHeight) as an ImageData
            // (Uint8ClampedArray of pixels) from our canvas

        if (trimWidth && trimWidth) {
            var trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

            // Draw back the ImageData into the canvas
            copy.canvas.width = trimWidth;
            copy.canvas.height = trimHeight;
            copy.putImageData(trimmed, 0, 0);

            const textHeight = copy.canvas.height;
            const textWidth = copy.canvas.width;
            copy_canvas.remove();
            canvas.remove();
            container.remove();
            initObjectGroup.add(obj);
            return {
                width: textWidth,
                height: textHeight,
                x: bound.left,
                y: bound.top
            }
        } else {
            copy_canvas.remove();
            canvas.remove();
            container.remove();
            initObjectGroup.add(obj);
            return {
                width: trimWidth,
                height: trimHeight,
                x: bound.left,
                y: bound.top
            }
        }
    }

    function checkText(visible_text, textObj) {
        let brokenWords = hasBrokenWords(textObj.getAttr('text'), textObj.textArr);
        let txtSize = getTextSize(textObj);
        return !brokenWords &&
            textObj.textArr.length <= maxLines &&
            txtSize.height <= textObj.height() &&
            txtSize.width <= textObj.width();
    }

    function hasBrokenWords(sourceTokens, renderLines) {
        let combined = collectVisibleText(renderLines);

        let a = sourceTokens;
        let b = combined;

        if (a.length !== b.length) {
            return true;
        }

        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return true;
            }
        }

        return false;
    }

    function collectVisibleText(textArr) {
        let combined_text = '';
        for (let i = 0; i < textArr.length; i++) {
            combined_text += (i === 0 || textArr[i-1].text.endsWith('-') || textArr[i].text.startsWith('-') ? "" : " ") + textArr[i].text;
        }
        return combined_text
    }
}

export function getTextComponent(
    feed,
    dynamic,
    text,
    currentFeedProduct,
    enableTransform,
    handleDragEnd,
    handleTransformEnd,
    removeTransform,
    selectLayer,
    getKonvaObjectSize,
    layer_id,
    x,
    y,
    width,
    height,
    rotation,
    fill,
    bgFill,
    align,
    verticalAlign,
    fontFamily,
    textDecoration,
    fontStyle,
    fontVariant,
    opacity,
    minFontSize,
    maxFontSize,
    maxLines,
    cutText,
    regexpMatch,
    regexpReplace,
    placeholderStatus,
    placeholderText,
    perfectToggle,
    substrateToggle,
    substrateFill,
    substrateTop,
    substrateRight,
    substrateBottom,
    substrateLeft,
    substrateCornerRadiusTL,
    substrateCornerRadiusTR,
    substrateCornerRadiusBR,
    substrateCornerRadiusBL
) {
    let dynamicText = feed[currentFeedProduct][text];
    if (dynamic) {
        if (placeholderText && placeholderStatus === true && !dynamicText.length) {
            dynamicText = placeholderText;
        }
        if (checkRegExp(regexpMatch) && regexpReplace !== undefined) {
            const regexp = new RegExp(regexpMatch);
            dynamicText = dynamicText.replace(regexp, regexpReplace).trim();
            dynamicText = dynamicText.replace(/\s{2,}/, ' ');
        }
    }

    return <TextAdaptive
        text={dynamic ? dynamicText : text}
        onClick={enableTransform}
        onDragStart={enableTransform}
        onDragEnd={handleDragEnd}
        onTransformEnd={handleTransformEnd}
        removeTransform={removeTransform}
        selectLayer={selectLayer}
        getKonvaObjectSize={getKonvaObjectSize}
        layer_id={layer_id}
        dynamic={dynamic ? text : false}
        x={x}
        y={y}
        width={width}
        height={height}
        rotation={rotation}
        fill={fill}
        bgFill={bgFill}
        align={align}
        verticalAlign={verticalAlign}
        fontFamily={fontFamily}
        textDecoration={textDecoration}
        fontStyle={fontStyle}
        fontVariant={fontVariant}
        opacity={opacity}
        minFontSize={minFontSize}
        maxFontSize={maxFontSize}
        maxLines={maxLines}
        cutText={cutText}
        regexpMatch={regexpMatch}
        regexpReplace={regexpReplace}
        placeholderStatus={placeholderStatus}
        placeholderText={placeholderText}
        perfectToggle={perfectToggle}
        substrateToggle={substrateToggle}
        substrateFill={substrateFill}
        substrateTop={substrateTop}
        substrateRight={substrateRight}
        substrateBottom={substrateBottom}
        substrateLeft={substrateLeft}
        substrateCornerRadiusTL={substrateCornerRadiusTL}
        substrateCornerRadiusTR={substrateCornerRadiusTR}
        substrateCornerRadiusBR={substrateCornerRadiusBR}
        substrateCornerRadiusBL={substrateCornerRadiusBL}
    />
}

class TextAdaptive extends React.Component {

    componentDidMount() {
        scaleText(this.textEl)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.text !== this.props.text ||
            prevProps.cutText !== this.props.cutText ||
            prevProps.perfectToggle !== this.props.perfectToggle
        ) {
            scaleText(this.textEl)
        }
    }

    render() {
        // TODO: catch CorpidE3SCd font resizing
        // if (this.textEl && this.props.fontFamily.indexOf('CorpidE3SCd') >= 0) {
        //     console.log(this.textEl.toJSON())
        // }
        return (
            <Group
                draggable
                onClick={this.props.onClick}
                onDragStart={this.props.onDragStart}
                onDragEnd={this.props.onDragEnd}
                onTransformEnd={this.props.onTransformEnd}
                listening={true}
                rotation={this.props.rotation || 0}
                layer_id={this.props.layer_id}
                name={'group-' + this.props.layer_id}
                x={this.props.x}
                y={this.props.y}
            >
                <Rect
                    ref={el => this.bgEl = el}
                    fill={this.props.bgFill || "rgba(0,0,0,0)"}
                    saved_fill={this.props.bgFill || "rgba(0,0,0,0)"}
                    width={this.bgEl ? this.bgEl.width() : (this.props.width || 110)} // Set bg size on initialization, don't update it later!
                    height={this.bgEl ? this.bgEl.height() : (this.props.height || 50)} // Set bg size on initialization, don't update it later!
                    name='no-transform'
                    listening={true}
                />
                <Rect
                    ref={el => this.substrateEl = el}
                    className='substrate'
                    substrateToggle={this.props.substrateToggle || false}
                    perfectToggle={this.props.perfectToggle || false}
                    fill={this.props.substrateToggle ? this.props.substrateFill || "rgba(0,0,0,0)" : "rgba(0,0,0,0)"}
                    saved_fill={this.props.substrateFill || "rgba(0,0,0,0)"}
                    width={this.props.substrateWidth || 110}
                    height={this.props.substrateHeight || 50}
                    name='no-transform-substrate'
                    listening={true}
                    cornerRadius={[
                        this.props.substrateCornerRadiusTL || 0,
                        this.props.substrateCornerRadiusTR || 0,
                        this.props.substrateCornerRadiusBR || 0,
                        this.props.substrateCornerRadiusBL || 0
                    ]}
                    substrateTop={this.props.substrateTop || 10}
                    substrateRight={this.props.substrateRight || 10}
                    substrateBottom={this.props.substrateBottom || 10}
                    substrateLeft={this.props.substrateLeft || 10}
                />
                <Text
                    text={this.props.text}
                    fullText={this.props.text}
                    textDecoration={this.props.textDecoration || "none"}
                    fill={this.props.fill || "rgba(0,0,0,1)"}
                    width={this.props.width || 100}
                    height={this.props.height || 50}
                    fontSize={50}
                    fontFamily={this.props.fontFamily || 'Arial Normal'}
                    fontStyle={this.props.fontStyle || "normal"}
                    fontVariant={this.props.fontVariant || "normal"}
                    align={this.props.align ? this.props.align.toLowerCase() : "center"}
                    verticalAlign={this.props.verticalAlign ? this.props.verticalAlign.toLowerCase() : "middle"}
                    listening={false}
                    layer_id={this.props.layer_id}
                    wrap="word"
                    ref={el => this.textEl = el}
                    dynamic={this.props.dynamic}
                    minFontSize={this.props.minFontSize || 10}
                    maxFontSize={this.props.maxFontSize || 50}
                    maxLines={this.props.maxLines || 3}
                    opacity={this.props.opacity}
                    cutText={this.props.cutText || true}
                    regexpMatch={this.props.regexpMatch || ""}
                    regexpReplace={this.props.regexpReplace || ""}
                    placeholderStatus={this.props.placeholderStatus ? this.props.placeholderStatus : false}
                    placeholderText={this.props.placeholderText ? this.props.placeholderText : ""}
                />
            </Group>
        );
    }
}

StaticTextSettings.contextType = GlobalContext;
DynamicTextSettings.contextType = GlobalContext;
export default TextAdaptive;