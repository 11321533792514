import React from 'react';
import {GlobalContext} from '../../contexts/GlobalContext.jsx'
import {
    Button,
    ButtonToolbar,
    Col,
    Dropdown,
    DropdownButton,
    Form,
    FormControl,
    InputGroup, // Modal,
    Row,
    ToggleButton,
    ToggleButtonGroup
} from 'react-bootstrap'
import {DynamicImageSettings, getImageComponent, StaticImageSettings} from "./ImageFromUrl.jsx";
import {DynamicTextSettings, getTextComponent, StaticTextSettings} from "./TextAdaptive.jsx";
import {findWithAttr} from "./ProjectPage.jsx";
import ColorPicker from "./ColorPicker.jsx";


class DropdownScreenSizesGenerator extends React.Component {

    render() {
        const {ajaxErrorCanvasSizes, isLoadedCanvasSizes, canvasSizes} = this.context;
        if (ajaxErrorCanvasSizes) {
            return <div>Error: {ajaxErrorCanvasSizes.message}</div>
        } else if (!isLoadedCanvasSizes) {
            return <div>Loading...</div>
        } else {
            let result = [];
            if (typeof canvasSizes !== "undefined" && canvasSizes.length > 0) {
                let lastPlatform = "";
                for (let row of canvasSizes) {
                    if (lastPlatform !== row.platform) {
                        lastPlatform = row.platform;
                        result.push(<Dropdown.Header key={row.platform}>{row.platform}</Dropdown.Header>);
                    }
                    result.push(
                        <Dropdown.Item
                            key={row.id}
                            eventKey={row.id}
                            onClick={this.context.changeCanvasSize}
                            data-x={row.x}
                            data-y={row.y}
                            active={(row.id === this.context.canvasSizesSelectedOption)}
                        >{row.format} ({row.x}x{row.y})</Dropdown.Item>);
                }
            }
            return (
                <DropdownButton
                    onSelect={this.context.handleSelect}
                    alignRight
                    title={`${this.context.canvasSize.width}x${this.context.canvasSize.height}`}
                    id="dropdown-menu-align-right"
                    variant="outline-secondary"
                >
                    {result}
                    <Dropdown.Divider/>
                    <Dropdown.Header key="custom-canvas-size">Custom size</Dropdown.Header>
                    <Dropdown.Item key={0} eventKey={0} active={this.context.canvasSizesSelectedOption === 0}>
                        {`${this.context.canvasSize.width}x${this.context.canvasSize.height}`}
                    </Dropdown.Item>
                </DropdownButton>
            )
        }
    }
}

class ProjectSettings extends React.Component {
    state = {};

    constructor(args) {
        super(args);

        this.fileReader = new FileReader();

        this.fileReader.onload = (event) => {
            this.context.importStage(JSON.parse(event.target.result));
        };

    }

    getBg = () => {
        let bg;
        if (this.context.main_stage) {
            bg = this.context.main_stage.findOne('.canvasSize');
            if (bg) {
                bg = bg.findOne('Rect');
            } else {
                this.forceUpdate();
            }
        } else {
            this.forceUpdate();
        }
        this.setState({bg: bg})
    };

    componentDidMount() {
        this.getBg()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState.bg) {
            this.getBg()
        }
    }

    onChangeDesignName = e => {
        this.context.designName = e.target.value;
        this.forceUpdate();
    };

    // handleLoadProjectClose = () => this.setState({showLoadProjectModal: false});
    //
    // handleLoadProjectShow = () => {
    //     this.setState({showLoadProjectModal: true})
    // };

    // loadFile = () => {
    //     if (this.fileUpload.files.length) {
    //         this.fileReader.readAsText(this.fileUpload.files[0]);
    //         this.handleLoadProjectClose();
    //     } else {
    //         alert('Select JSON file to load it!')
    //     }
    // };

    render() {
        return (
            <Form>
                <h3>Design Settings</h3>
                <Form.Group controlId="formProjectName">
                    <Form.Label>Design name</Form.Label>
                    <Form.Control
                        type="text"
                        name="projectName"
                        placeholder="Enter template name"
                        onChange={this.onChangeDesignName}
                        value={this.context.designName}
                        ref={input => this.projectNameInput = input}
                    />
                </Form.Group>

                <Form.Group controlId="formSettedCanvasSize">
                    <Form.Label>Canvas size</Form.Label>
                    <DropdownScreenSizesGenerator data={this.context.canvasSizesData}/>
                </Form.Group>

                <Form.Group controlId="formCustomCanvasSize">
                    {/*<Form.Label>Custom canvas size</Form.Label>*/}
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>W</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="number" name="customCanvasSizeWidth"
                                             onChange={this.context.handleCustomCanvasSize}
                                             value={this.context.canvasSize.width}/>
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>H</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="number" name="customCanvasSizeHeight"
                                             onChange={this.context.handleCustomCanvasSize}
                                             value={this.context.canvasSize.height}/>
                            </InputGroup>
                        </Col>
                    </Row>
                </Form.Group>
                {this.state.bg &&
                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Bg color</InputGroup.Text>
                        </InputGroup.Prepend>
                        <ColorPicker obj={this.state.bg}/>
                    </InputGroup>
                </Form.Group>}

                <Form.Label>Outline area</Form.Label>
                <ButtonToolbar>
                    <ToggleButtonGroup type="radio" onChange={this.context.toggleOutlineArea} name="formOutlineArea"
                                       defaultValue={this.context.outlineVisiblity}>
                        <ToggleButton
                            variant="outline-secondary"
                            value={false}
                        >
                            Hide
                        </ToggleButton>
                        <ToggleButton
                            variant="outline-secondary"
                            value={true}
                        >
                            Show
                        </ToggleButton>
                    </ToggleButtonGroup>
                </ButtonToolbar>

                <br/>

                {/* Buttons */}

                <Button variant="primary" size="lg" block onClick={() => {
                    if (this.projectNameInput.value) {
                        this.context.downloadStage()
                    } else {
                        alert('Design name is required');
                        this.projectNameInput.focus();
                    }
                }}>
                    Save
                </Button>

                {/*<Button variant="primary" size="lg" block onClick={() => this.handleLoadProjectShow()}>*/}
                {/*    Load project*/}
                {/*</Button>*/}
                {/*{this.state.showLoadProjectModal !== undefined &&*/}
                {/*<>*/}
                {/*    <Modal show={this.state.showLoadProjectModal} onHide={this.handleLoadProjectClose}>*/}
                {/*        <Modal.Header closeButton>*/}
                {/*            <Modal.Title>Load project from JSON</Modal.Title>*/}
                {/*        </Modal.Header>*/}
                {/*        <Modal.Body className="d-flex flex-column">*/}
                {/*            <Form onSubmit={() => {this.context.importStage(this.loadProjectInput.value);this.handleLoadProjectClose()}}>*/}
                {/*                <Form.Group controlId="loadProjectForm.ControlTextarea">*/}
                {/*                    <Form.Label>JSON:</Form.Label>*/}
                {/*                    <Form.Control size="sm" as="textarea" ref={input => this.loadProjectInput = input}/>*/}
                {/*                </Form.Group>*/}
                {/*            </Form>*/}
                {/*            <Button variant="primary" onClick={() => {this.context.importStage(this.loadProjectInput.value);this.handleLoadProjectClose()}}>*/}
                {/*                Load from JSON text*/}
                {/*            </Button>*/}
                {/*            <hr />*/}
                {/*            <Form.Control*/}
                {/*                type='file' label='Upload .json' accept='.json' placeholder='Upload .json'*/}
                {/*                ref={(ref) => this.fileUpload = ref}*/}
                {/*            />*/}
                {/*            <Button variant="primary" onClick={this.loadFile}>*/}
                {/*                Load from .json file*/}
                {/*            </Button>*/}
                {/*        </Modal.Body>*/}
                {/*        <Modal.Footer>*/}
                {/*            <Button variant="secondary" onClick={this.handleLoadProjectClose}>*/}
                {/*                Close*/}
                {/*            </Button>*/}
                {/*        </Modal.Footer>*/}
                {/*    </Modal>*/}
                {/*</>*/}
                {/*}*/}

            </Form>
        );
    }
}

export function deleteLayer(app, layer_id) {
    layer_id = parseInt(layer_id);
    app.state.removeTransform();
    let layers = [...app.state.layers];
    const layer_index = findWithAttr(layers, 'id', layer_id);
    const deletedLayerId = app.state.layers[layer_index].id;
    layers.splice(layer_index, 1);
    let key_ids = layers.map(a => a.key_id);
    for (let i = 0; i < layers.length; i++) {
        if (layers[i].id > deletedLayerId) {
            const target_layer = app.state.main_stage.findOne('.layer-' + layers[i].id);
            const target_group = target_layer.findOne('Group');
            const target_rect = target_group.findOne('.no-transform');
            const target_substrate = target_group.findOne('.no-transform-substrate');
            const substrateCorners = target_substrate.cornerRadius();
            const dynamic = layers[i].dynamic;
            const l_id = layers[i].id - 1;
            if (layers[i].type === "Image") {
                const url = layers[i].url;
                const img = target_group.findOne('Image');
                layers[i].component =
                    () => getImageComponent(
                        app.state.feed,
                        dynamic,
                        dynamic || url,
                        app.state.currentFeedProduct,
                        app.state.enableTransform,
                        app.state.handleDragEnd,
                        app.state.handleTransformEnd,
                        app.state.removeTransform,
                        app.state.selectLayer,
                        app.state.getKonvaObjectSize,
                        l_id,
                        target_group.x(),
                        target_group.y(),
                        target_rect.width(),
                        target_rect.height(),
                        target_group.rotation(),
                        img.getAttr('mode'),
                        target_rect.getAttr('saved_fill'),
                        target_group.getAttr('saved_opacity')
                    );
            } else {
                const target_text = target_group.findOne('Text');
                layers[i].component =
                    () => getTextComponent(
                        app.state.feed,
                        dynamic,
                        dynamic || target_text.text(),
                        app.state.currentFeedProduct,
                        app.state.enableTransform,
                        app.state.handleDragEnd,
                        app.state.handleTransformEnd,
                        app.state.removeTransform,
                        app.state.selectLayer,
                        app.state.getKonvaObjectSize,
                        l_id,
                        target_group.x(),
                        target_group.y(),
                        target_rect.width(),
                        target_rect.height(),
                        target_group.rotation(),
                        target_text.fill(),
                        target_rect.getAttr('saved_fill'),
                        target_text.align(),
                        target_text.verticalAlign(),
                        target_text.fontFamily(),
                        target_text.textDecoration(),
                        target_text.fontStyle(),
                        target_text.fontVariant(),
                        target_text.opacity(),
                        target_text.getAttr('minFontSize'),
                        target_text.getAttr('maxFontSize'),
                        target_text.getAttr('maxLines'),
                        target_text.getAttr('cutText'),
                        target_text.getAttr('regexpMatch'),
                        target_text.getAttr('regexpReplace'),
                        target_text.getAttr('placeholderStatus'),
                        target_text.getAttr('placeholderText'),
                        target_substrate.getAttr('perfectToggle'),
                        target_substrate.getAttr('substrateToggle'),
                        target_substrate.getAttr('saved_fill'),
                        target_substrate.getAttr('substrateTop'),
                        target_substrate.getAttr('substrateRight'),
                        target_substrate.getAttr('substrateBottom'),
                        target_substrate.getAttr('substrateLeft'),
                        substrateCorners[0],
                        substrateCorners[1],
                        substrateCorners[2],
                        substrateCorners[3]
                    );
            }
            layers[i].id -= 1;
            layers[i].content = `Layer ${layers[i].id + 1} (${layers[i].type})`;
            if (layers[i].key_id in key_ids) {
                layers[i].key_id = Math.max.apply(Math, layers.map(function (o) {
                    return o.key_id;
                })) + 1;
            }
        }
    }
    app.setState({
        layers: layers
    });
}

class LayerSettings extends React.Component {
    state = {};

    componentDidMount() {

        let target_group = this.context.main_stage.findOne('.' + this.props.selectedLayer);
        let target_shape = target_group.findOne('Text') || target_group.findOne('Image');

        this.setState({
            selectedShape: target_shape, selectedLayer: target_group.getAttr('layer_id')
        });
    }

    componentDidUpdate(old_props) {
        if (old_props.selectedLayer !== this.props.selectedLayer) {
            const target_group = this.context.main_stage.findOne('.' + this.props.selectedLayer);
            const target_shape = target_group.findOne('Text') || target_group.findOne('Image');
            this.setState({selectedShape: target_shape, selectedLayer: target_group.getAttr('layer_id')})
        }
    }

    render() {
        let content;
        if (this.state.selectedShape) {
            if (this.state.selectedShape.className === 'Image') {
                if (this.state.selectedShape.getAttr('dynamic')) {
                    content = <DynamicImageSettings selectedShape={this.state.selectedShape}/>
                } else {
                    content = <StaticImageSettings selectedShape={this.state.selectedShape}/>
                }
            } else if (this.state.selectedShape.className === 'Text') {
                if (this.state.selectedShape.getAttr('dynamic')) {
                    content = <DynamicTextSettings selectedShape={this.state.selectedShape}/>
                } else {
                    content = <StaticTextSettings selectedShape={this.state.selectedShape}/>
                }
            }
            return <>
                {content}
                <Button variant="danger" size="lg" block
                        onClick={() => this.context.deleteLayer(this.state.selectedLayer)}>Delete</Button>
            </>
        } else {
            return null
        }
    }
}

class RightFilter extends React.Component {

    render() {
        return (
            <Col xs={3} className='side-filter'>
                {
                    !this.context.selectedShapeName &&
                    <ProjectSettings/>
                }
                {
                    this.context.selectedShapeName &&
                    <LayerSettings selectedLayer={this.context.selectedShapeName}/>
                }
            </Col>
        );
    }
}

DropdownScreenSizesGenerator.contextType = GlobalContext;
ProjectSettings.contextType = GlobalContext;
LayerSettings.contextType = GlobalContext;
RightFilter.contextType = GlobalContext;
export default RightFilter;
