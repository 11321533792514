import React, {Component} from 'react';
import {GlobalContext} from '../../contexts/GlobalContext.jsx';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";


export function onListDragEnd(app, result) {
    // dropped outside the list
    if (!result.destination) {
        return;
    }

    // Reverse to get menu order
    const new_layers = Array.from(app.state.layers).reverse();
    const items = reorder(
        new_layers,
        result.source.index,
        result.destination.index
    );
    app.setState({
        layers: items.reverse() // Reverse to restore layers order
    });
}


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};


const grid = 0;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 5,
    margin: `0 0 0 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",
    width: '100%',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: '100%'
});

class LayersList extends Component {

    render() {
        const layers = Array.from(this.context.layers).reverse();
        return <div className='layers-list no-padding'>
            <div style={{padding: 15}}>
                <h3 style={{margin: 0}}>Layers list:</h3>
            </div>
            {Boolean(layers.length) ?
                <DragDropContext onDragEnd={this.context.onListDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {layers.map((item, index) => (
                                    <Draggable key={item.key_id} draggableId={item.key_id.toString()} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                                onClick={(e) => this.context.selectLayer(e.target.getAttribute('layer_id'))}
                                                layer_id={item.id}
                                            >
                                                {item.content}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                :
                <div style={{padding: 15}}>
                    <p>No layers,<br/>👈 add some)</p>
                </div>
            }
        </div>;
    }
}

LayersList.contextType = GlobalContext;
export default LayersList;
