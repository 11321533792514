import React, {Component, useState} from 'react';
import {Button, Card, Col, Empty, Input, message, Popconfirm, Radio, Row, Select, Space, Tag, Tooltip} from 'antd';
import {WarningOutlined} from "@ant-design/icons";

export class FeedConfigs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        };
    }

    onClickNewFeedConfig = () => {
        const actionFromData = {
            data: this.state.data,
            title: "New feed config",
            method: "create",
            updateTabCounter: this.props.updateTabCounter
        };
        this.props.handleHeaderUpdate({
            headerType: "header-feed-configs-editor",
            headerBreadcrumbIndex: "DOT",
            headerBreadcrumbFirst: "Feed Configs",
            headerBreadcrumbSecond: actionFromData.title,
            headerTitle: actionFromData.title,
            headerExtra: [<Button key="btn-back-in-new-feed-configs" onClick={() => this.props.back()}>Back</Button>],
            actionFrom: actionFromData
        });
    }

    onClickDeleteFeedConfig = feedConfigId => {
        const key = "message-delete-feed-config";
        const url = `/projects/${this.state.data.id}/outputs/${feedConfigId}/`;
        message.loading({
            content: "Deleting feed config...",
            key: key,
            duration: 3
        });
        this.props.fetch(
            url,
            {method: "DELETE"}
        ).then(
            () => {
                message.success({
                    content: "Feed config has been deleted!",
                    key: key,
                    duration: 3
                });
                const newData = this.state.data;
                newData.outputs = newData.outputs.filter(item => item.id !== feedConfigId)
                this.setState({
                        data: newData
                    },
                    () => this.props.updateTabCounter("feedConfigsCount", newData.outputs.length));

            },
            err => {
                message.error({
                    content: "Error!",
                    key: key,
                    duration: 3
                });
            }
        )
    }

    render() {

        const feedConfigs = (
            this.state.data.outputs.length > 0 ?
                this.state.data.outputs.map(output => <FeedConfigCard
                    key={`feed-config-card-${output.id}`}
                    data={output}
                    handleHeaderUpdate={this.props.handleHeaderUpdate}
                    delete={this.onClickDeleteFeedConfig}
                />)
                :
                <Empty description="No feed configs"/>
        )

        const onNewFeedConfig = (
            <Button type="primary" onClick={() => this.onClickNewFeedConfig()}>New feed config</Button>
        );

        return (
            <>
                {onNewFeedConfig}
                <Space direction="vertical" style={{width: "100%"}}>
                    {feedConfigs}
                </Space>
            </>
        )
            ;
    }
}


const FeedConfigCard = props => {

    const validation = (
        <Space>
            {
                props.data.valid ?
                    <Tag key={`tag-${props.data.id}-valid`} color="green">Valid</Tag>
                    :
                    <Tag key={`tag-${props.data.id}-valid`} color="red">Not valid</Tag>
            }
            {
                !props.data.valid &&
                <Tooltip title="A list of errors...">
                    <WarningOutlined style={{color: "red"}}/>
                </Tooltip>
            }

        </Space>
    );

    const onClickEditFeedConfig = () => {
        const actionFromData = {
            data: props.data,
            title: "Edit feed config",
            method: "edit"
        };
        props.handleHeaderUpdate({
            headerType: "header-feed-configs-editor",
            headerBreadcrumbIndex: "DOT",
            headerBreadcrumbFirst: "Feed Configs",
            headerBreadcrumbSecond: actionFromData.title,
            headerTitle: actionFromData.title,
            headerExtra: [<Button key="btn-back-in-new-feed-configs" onClick={() => this.props.back()}>Back</Button>],
            actionFrom: actionFromData
        });
    };

    const onDeleteCancel = () => {
        message.info("Cancelled.")
    }

    return (
        <Card
            size="small"
            bordered={true}
            title={<b>{props.data.name}</b>}
            extra={
                <Space>
                    <Button onClick={onClickEditFeedConfig}>Edit</Button>
                    <Popconfirm
                        title="Are you sure to delete this feed config?"
                        onConfirm={() => props.delete(props.data.id)}
                        onCancel={onDeleteCancel}
                        okText="Yes, I'm sure"
                        cancelText="No"
                    >
                        <Button danger type="primary">Delete</Button>
                    </Popconfirm>
                </Space>
            }
        >
            <Row key={`template-info-${props.data.id}`}>
                <Col span={24}>
                    <Space direction="vertical">
                        <span><b>Created:</b> {new Date(props.data.created).toLocaleString()}</span>
                        {validation}
                        <Tag key={`tag-${props.data.id}-not-use`} color="orange">Not in use</Tag>
                    </Space>
                </Col>
            </Row>
        </Card>
    );
}


export const FeedConfigEditor = props => {

    const [radioValue, setRadioValue] = useState(0);
    const onChangeRadioGroupSocialNets = e => {
        const value = e.target.value;
        setRadioValue(value);
    };

    const [feedConfigName, setFeedConfigName] = useState(
        props.actionFrom.method === "create" ?
            "" : props.actionFrom.data.name
    );

    const outputTypes = {
        0: {
            full: "Facebook (tsv)",
            short: "FB"
        },
        1: {
            full: "Yandex (yml)",
            short: "YD"
        },
        2: {
            full: "Custom (csv)",
            short: "CM"
        }
    }
    const radioGroupSocialNets = Object.entries(outputTypes)
        .map((v, k) => <Radio value={k}>{outputTypes[k].full}</Radio>);

    const fields = {
        source: [
            "one", "two", "three"
        ],
        output: [
            "one1", "two2", "three3"
        ]
    };

    const onSaveFeedConfig = () => {
        if (feedConfigName === "") {
            message.warning("Please type a feed config name");
            return false;
        }
        const key = "message-new-feed-config";
        const url = props.actionFrom.method === "create" ?
            `/projects/${props.actionFrom.data.id}/outputs/`
            :
            `/projects/${props.actionFrom.data.project}/outputs/${props.actionFrom.data.id}/`
        message.loading({
            content: props.actionFrom.method === "create" ?
                "Creating feed config..." : "Sending updates...",
            key: key,
            duration: 3
        });
        props.fetch(
            url,
            {
                method: props.actionFrom.method === "create" ?
                    "POST" : "PUT",
                body: JSON.stringify({
                    name: feedConfigName,
                    config_json: "test",  // TODO: Make a config JSON builder.
                    output_type: outputTypes[radioValue].short
                })
            }
        ).then(
            () => {
                message.success({
                    content: props.actionFrom.method === "create" ?
                        "New feed config has been created!" : "Feed has been updated!",
                    key: key,
                    duration: 3
                });
                // props.updateTabCounter("feedConfigsCount", props.)
                setTimeout(() => props.back(), 1000);
            },
            () => {
                message.error({
                    content: "Error!",
                    key: key,
                    duration: 3
                });
            }
        )
    };

    const onChangeInput = e => {
        const name = e.target.value;
        setFeedConfigName(name);
    }

    // TODO: Need to know field names for Facebook, Yandex and other platforms
    // TODO: Make a dictionary with field relations and default values
    return (

        <Card
            bordered={false}
            style={{boxShadow: "-2px 2px 10px lightgray"}}
            // title={<b>{props.actionFrom.title}</b>}
            actions={[
                <span onClick={() => props.back()}>Cancel</span>,
                <span onClick={onSaveFeedConfig}>Save</span>
            ]}
        >
            <Space direction="vertical" size="large" style={{width: "100%"}}>
                <Row key="1">
                    <Col span={24}>
                        <h6>Feed config name</h6>
                        <Input value={feedConfigName} onChange={onChangeInput}/>
                    </Col>
                </Row>
                <Row key="2">
                    <Col span={24}>
                        <Radio.Group onChange={onChangeRadioGroupSocialNets} value={radioValue}>
                            {radioGroupSocialNets}
                        </Radio.Group>
                    </Col>
                </Row>
                <Row key="3">
                    <Col span={24}>
                        <Space>
                            <WarningOutlined/>
                            <span>Please note that facebook tsv format is also supported by Google Shopping and myTarget
                                while Yandex yml format is supported by VK and myTarget as well.</span>
                        </Space>
                    </Col>
                </Row>
                <Row key="4">
                    <Col>
                        <p>Please set up the fields mapping.</p>
                    </Col>
                </Row>
                <Row key="5">
                    <Col span={12}>
                        <h6>Source fields:</h6>
                        <Space direction="vertical" style={{width: "100%"}}>
                            <Select defaultValue="one" style={{width: "90%"}}>
                                {
                                    fields.source.map(field => <Select.Option value={field}>{field}</Select.Option>)
                                }
                            </Select>
                            <Select defaultValue="one" style={{width: "90%"}}>
                                {
                                    fields.source.map(field => <Select.Option value={field}>{field}</Select.Option>)
                                }
                            </Select>
                            <Select defaultValue="one" style={{width: "90%"}}>
                                {
                                    fields.source.map(field => <Select.Option value={field}>{field}</Select.Option>)
                                }
                            </Select>
                        </Space>
                    </Col>
                    <Col span={12}>
                        <h6>Output fields:</h6>
                        <Space direction="vertical" style={{width: "100%"}}>
                            <Select defaultValue="one" style={{width: "90%"}}>
                                {
                                    fields.output.map(field => <Select.Option value={field}>{field}</Select.Option>)
                                }
                            </Select>
                            <Select defaultValue="one" style={{width: "90%"}}>
                                {
                                    fields.output.map(field => <Select.Option value={field}>{field}</Select.Option>)
                                }
                            </Select>
                            <Select defaultValue="one" style={{width: "90%"}}>
                                {
                                    fields.output.map(field => <Select.Option value={field}>{field}</Select.Option>)
                                }
                            </Select>
                        </Space>
                    </Col>
                </Row>
            </Space>
        </Card>
    );
}
