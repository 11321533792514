import React from "react";
import {Form} from "react-bootstrap";
import {scaleText} from "./TextAdaptive.jsx";

class DropdownGenerator extends React.Component {

    capitalize = (val) => {
        return val[0].toUpperCase() + val.slice(1)
    };

    handleChange = (e) => {
        e.preventDefault();
        const objMethod = this.props.objMethod;
        const value = objMethod === "fontFamily" ? e.target.value : e.target.value.toLowerCase();
        this.props.obj[objMethod](value);
        this.forceUpdate();
        scaleText(this.props.obj);
        this.props.obj.getLayer().batchDraw();
    };

    render () {
        const objMethod = this.props.objMethod;
        const selectValue = this.props.obj[objMethod]();
        let options = [];

        for (const [index, value] of this.props.data.entries()) {
            options.push(
                <option key={index}>
                    {value}
                </option>
            )
        }

        return (
            <Form.Control
                as="select"
                value={
                    selectValue !== undefined
                        ? objMethod === "fontFamily" ? selectValue : this.capitalize(selectValue)
                        : "None"
                }
                onChange={this.handleChange}
            >
                {options}
            </Form.Control>
        )
    }
}

export default DropdownGenerator;