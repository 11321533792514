import React, {Component} from "react";
import {
    Badge,
    Button,
    Card,
    Col,
    Descriptions,
    Empty,
    Input,
    message,
    Pagination,
    Row,
    Space,
    Tag,
    Tooltip
} from "antd";
import styled from "styled-components";
import {WarningOutlined} from "@ant-design/icons";

export class Info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Behaviour
            isNew: this.props.data === null,

            // Inputs
            dataFeedUrlInput: this.props.data !== null ? this.props.data.feed_url : "",

            // Pagination
            pageSize: 5,
            pageFrom: 0,
            pageTo: 5,
            currentPage: 1,
        };
    }

    onClickValid = () => {
        // Validate URL
        let url;
        const warningMessage = "Please type correct URL address";
        try {
            url = new URL(this.state.dataFeedUrlInput);
            if (!url.hostname.includes('.')) {
                message.warn(warningMessage);
                return false;
            }
        } catch (_) {
            message.warn(warningMessage);
            return false;
        }

        const key = "save-or-update-data-feed-url";
        message.loading({
            content: "Please, wait...",
            key: key,
            duration: 3
        });
        const createUrl = `/projects/${this.props.projectId}/feeds/`;
        const updateUrl = `/projects/${this.props.projectId}/feeds/${this.props.data?.id}/`;
        this.props.fetch(
            this.state.isNew ? createUrl : updateUrl,
            {
                method: this.state.isNew ? "POST" : "PUT",
                body: JSON.stringify({
                    name: `${this.props.projectId}_${this.state.dataFeedUrlInput}`,
                    feed_url: this.state.dataFeedUrlInput
                })
            }
        ).then(
            () => {
                message.success({
                    content: this.state.isNew ? "Data Feed has been uploaded!" : "Data Feed URL has been updated!",
                    key: key,
                    duration: 3
                });
            },
            err => {
                console.error(err.message);
                message.error({
                    content: "Error!",
                    key: key,
                    duration: 3
                });
            }
        )
    }

    onChangeDataFeedUrl = e => {
        e.preventDefault();
        const url = e.target.value;
        this.setState({dataFeedUrlInput: url});
    }

    render() {
        const StyledValidButton = styled(Button)`
          margin-left: 15px;
        `;

        const uploadDataFeed = (
            <Card
                bordered={false}
            >
                <h6>Data Feed URL</h6>
                <Row>
                    <Col span={22}>
                        <Input
                            value={this.state.dataFeedUrlInput}
                            placeholder="Put here Data Feed URL"
                            onChange={this.onChangeDataFeedUrl}
                        />
                    </Col>
                    <Col span={2}>
                        <StyledValidButton
                            type="primary"
                            onClick={this.onClickValid}
                        >
                            Valid
                        </StyledValidButton>
                    </Col>
                </Row>
            </Card>
        );

        const onPageChange = value => {
            this.setState({
                pageFrom: (value - 1) * this.state.pageSize,
                pageTo: value * this.state.pageSize,
                currentPage: value
            })
        };

        const itemRender = (current, type, originalElement) => {
            if (type === 'prev') {
                return <span>Previous logs</span>;
            }
            if (type === 'next') {
                return <span>Next logs</span>;
            }
            return originalElement;
        };

        const pagination = (
            <Row type="flex" justify="center" align="middle" style={{padding: 15}} key="pagination">
                <Col>
                    <Pagination
                        current={this.state.currentPage}
                        pageSize={this.state.pageSize}
                        showQuickJumper
                        itemRender={itemRender}
                        onChange={onPageChange}
                        total={this.props.data !== null ? this.props.data.validation_log_json.length : 0}
                    />
                </Col>
            </Row>
        );

        const reversedList = this.props.data !== null ? [].concat(this.props.data.validation_log_json).reverse() : [];

        return (
            <Space direction="vertical" style={{width: "100%"}}>
                {uploadDataFeed}
                {
                    this.state.data !== null &&
                    <ValidationLogList
                        data={reversedList.slice(this.state.pageFrom, this.state.pageTo)}
                    />
                }
                {
                    this.props.data !== null ?
                        this.props.data.validation_log_json.length > 5 ?
                            pagination
                            :
                            null
                        :
                        null
                }
            </Space>
        );
    }
}


const ValidationLogList = props => {
    if (props.data.length) {
        return (
            <Space direction="vertical">
                {props.data.map(log => <ValidationLogDetail key={`validation-log-${log.id}`} data={log}/>)}
            </Space>
        );
    } else {
        return (
            <Row key="no-data-feed-log-infos">
                <Col span={24} type="flex" justify="center" align="middle">
                    <Empty description={""}/>
                </Col>
            </Row>
        );
    }
}


const ValidationLogDetail = props => {


    const logData = JSON.parse(props.data.log_json);
    const rejected = logData.raw_cnt - logData.valid_cnt;
    const rejectedInPercent = Boolean(rejected) ? (rejected * 100) / logData.raw_cnt : 0;

    const leftColStyle = {
        width: "35%"
    };

    const rightColStyle = {
        width: "65%"
    };

    const downloadReportTooltipMessages = logData.errors.map(
        (msg, index) => `[${index + 1}] Type: ${msg.type}; Message: ${msg.error}\n`
    );

    const logStatus = !!logData.errors.length ?
        <Badge style={{paddingLeft: 10}} dot={true} status="warning"/>
        :
        <Badge style={{paddingLeft: 10}} dot={true} status="success"/>

    return (
        <Card
            bordered={true}
        >
            <Row key={`validation-log-${props.data.feed}-${props.data.id}`}>
                <Col style={leftColStyle}>
                    <Space direction="vertical">
                                <span>
                                    <b>{new Date(props.data.created).toLocaleString()}</b>
                                    <Tooltip title="Success">
                                        {logStatus}
                                    </Tooltip>
                                </span>
                        <span
                            style={{fontStyle: "italic", color: "lightgray", fontSize: "0.8rem"}}
                        >
                            {
                                // TODO: Waiting for need data from backend
                            }
                            Type of Upload (not from DB)
                        </span>
                    </Space>
                </Col>
                <Col style={rightColStyle}>
                    <Space direction="vertical">
                        <Descriptions column={1}>
                            <Descriptions.Item>
                                <Tag>{`${logData.raw_cnt}`}</Tag> products found in file
                            </Descriptions.Item>
                            <Descriptions.Item>
                                <Tag color="success">{logData.valid_cnt}</Tag> valid products
                            </Descriptions.Item>
                            {
                                !!logData.errors.length &&
                                <Descriptions.Item>
                                    <Tag color="warning">{rejected}</Tag> products rejected due to errors
                                    ({rejectedInPercent}%)
                                </Descriptions.Item>
                            }

                        </Descriptions>
                        {
                            !!logData.errors.length &&
                            <Space size="middle">
                                <Tooltip title={downloadReportTooltipMessages}>
                                    <Button
                                        icon={<WarningOutlined/>}
                                        danger={true}
                                        type="dashed"
                                        onClick={() => alert("Download report.")}  // TODO: handleDownloadReport
                                    >
                                        Download report
                                    </Button>
                                </Tooltip>
                            </Space>

                        }
                    </Space>
                </Col>
            </Row>
        </Card>
    );
}