import React, {Component} from 'react';
import {Button, Card, Col, Empty, Input, message, Pagination, Row, Space, Statistic} from 'antd';
import {Project} from './project';
import {FeedConfigEditor} from "../FeedConfig/feedConfig";
import {FeedRuleEditor} from "../FeedRule/feedRule";

export class ProjectsContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // Inputs
            lastProjectId: undefined,
            editProjectName: "",

            // Pagination
            pageSize: 5,
            pageFrom: 0,
            pageTo: 5,
            currentPage: 1,
        };
        this.onOpenNewProject = this.onOpenNewProject.bind(this);
        this.itemRender = this.itemRender.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onOpenProjectsContent = this.onOpenProjectsContent.bind(this);
        this.onOpenNewProject = this.onOpenNewProject.bind(this);
        this.onChangeProjectName = this.onChangeProjectName.bind(this);
    }

    onPageChange = value => {
        this.setState({
            pageFrom: (value - 1) * this.state.pageSize,
            pageTo: value * this.state.pageSize,
            currentPage: value
        })
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <span>Previous</span>;
        }
        if (type === 'next') {
            return <span>Next</span>;
        }
        return originalElement;
    };

    onOpenNewProject() {
        this.props.handleHeaderUpdate({
            headerType: "header-projects-new-project",
            headerBreadcrumbIndex: "DOT",
            headerBreadcrumbFirst: "Projects",
            headerBreadcrumbSecond: "New project",
            headerTitle: "New project",
            headerExtra: [<Button key="btn-back-to-projects" onClick={() => this.onOpenProjectsContent()}>Back</Button>]
        });
    };

    onOpenProjectsContent(forseLoad = false) {
        this.props.handleHeaderUpdate({
            headerType: "header-projects-content",
            headerBreadcrumbIndex: "DOT",
            headerBreadcrumbFirst: "Projects",
            headerBreadcrumbSecond: undefined,
            headerTitle: "Projects",
            headerExtra: [<Button key="btn-new-project" onClick={() => this.onOpenNewProject()}>New Project</Button>]
        });
        this.props.handleLoadProjects(forseLoad);
    };

    onChangeProjectName = e => {
        e.preventDefault();
        const name = e.target.value;
        this.setState({
            editProjectName: name
        });
    };

    onOpenRenameProjectName = (projectId, projectName) => {
        this.props.handleHeaderUpdate({
            headerType: "header-rename-project",
            headerBreadcrumbIndex: "DOT",
            headerBreadcrumbFirst: "Projects",
            headerBreadcrumbSecond: "Rename project",
            headerTitle: "Rename project",
            headerExtra: [<Button key="btn-back-in-rename-project" onClick={() => this.props.back}>Back</Button>]
        });
        this.setState({
            lastProjectId: projectId,
            editProjectName: projectName
        });
    };

    onSaveNewProject() {
        const key = "message-new-project";
        message.loading({
            content: "Creating project...",
            key: key,
            duration: 3
        });
        this.props.fetch(
            `/projects/`,
            {
                method: "POST",
                body: JSON.stringify({
                    name: this.state.editProjectName
                })
            }
        ).then(
            () => {
                message.success({
                    content: "New project has been created!",
                    key: key,
                    duration: 3
                });
                setTimeout(() => this.onOpenProjectsContent(true), 1000);
            },
            () => {
                message.error({
                    content: "Error!",
                    key: key,
                    duration: 3
                });
            }
        )
    };

    onSaveRenamedProject() {
        this.props.handleRenameProject(
            this.state.editProjectName,
            this.state.lastProjectId
        );
        setTimeout(() => this.onOpenProjectsContent(true), 1000);
    };

    componentDidMount() {
        // Update ProjectsHeader
        if (this.props.headerType === "header-projects-content") {
            this.onOpenProjectsContent();
        } else if (this.props.headerType === "header-projects-new-project") {
            this.onOpenNewProject();
        }
    }

    render() {
        // -----------------[ Pages ]-----------------
        const newProject = (
            <Row key="projects-new-project">
                <Col span={24}>
                    <Card
                        bordered={false}
                        style={{boxShadow: "-2px 2px 10px lightgray"}}
                        actions={[
                            <span onClick={() => this.onOpenProjectsContent()}>Cancel</span>,
                            <span onClick={() => this.onSaveNewProject()}>Save</span>
                        ]}
                    >
                        <Space direction="vertical" style={{width: "100%"}} size="large">
                            <h5>Project name</h5>
                            <Input placeholder="Type a name of the new project" onChange={this.onChangeProjectName}/>
                        </Space>
                    </Card>
                </Col>
            </Row>
        );

        const renameProject = (
            <Row key="projects-rename-project">
                <Col span={24}>
                    <Card
                        bordered={false}
                        style={{boxShadow: "-2px 2px 10px lightgray"}}
                        actions={[
                            <span onClick={() => this.onOpenProjectsContent()}>Cancel</span>,
                            <span onClick={() => this.onSaveRenamedProject()}>Save</span>
                        ]}
                    >
                        <Space direction="vertical" style={{width: "100%"}} size="large">
                            <h5>Project name</h5>
                            <Input
                                placeholder="Type a new name of this project"
                                onChange={this.onChangeProjectName}
                                value={this.state.editProjectName}
                            />
                        </Space>
                    </Card>
                </Col>
            </Row>
        )

        const feedConfigEditor = (
            <FeedConfigEditor
                back={this.onOpenProjectsContent}
                actionFrom={this.props.actionFrom}
                fetch={this.props.fetch}
            />
        );

        const feedRuleEditor = (
            <FeedRuleEditor
                back={this.onOpenProjectsContent}
                actionFrom={this.props.actionFrom}
                fetch={this.props.fetch}
            />
        );

        // -----------------[ Main projects content ]-----------------
        const projects = this.props.projects.slice(this.state.pageFrom, this.state.pageTo).map(project => (
            <Project
                key={`project-${project.id}`}
                project={project}
                actionFrom={this.props.actionFrom}
                delete={this.props.handleDeleteProject}
                rename={this.props.handleRenameProject}
                fetch={this.props.fetch}
                back={this.onOpenProjectsContent}
                handleHeaderUpdate={this.props.handleHeaderUpdate}
                onChangeProjectName={this.onChangeProjectName}
                onOpenRenameProjectName={this.onOpenRenameProjectName}
            />
        ));

        const pagination = (
            <Row type="flex" justify="center" align="middle" style={{padding: 15}} key="pagination">
                <Col>
                    <Pagination
                        current={this.state.currentPage}
                        pageSize={this.state.pageSize}
                        showQuickJumper
                        itemRender={this.itemRender}
                        onChange={this.onPageChange}
                        total={this.props.projects.length}
                    />
                </Col>
            </Row>
        );

        const statistics = (
            <Row key="statistics-info" gutter={8}>
                <Col span={8} type="flex" justify="center" align="middle">
                    <Statistic title="Total projects" value={this.props.projects.length}/>
                </Col>
                <Col span={8} type="flex" justify="center" align="middle">
                    <Statistic title="Total items" value={9999}/>
                </Col>
                <Col span={8} type="flex" justify="center" align="middle">
                    <Statistic title="Balance" value={999999.12} prefix={`${this.props.currency} `}/>
                </Col>
            </Row>
        );

        // -----------------[ Content switcher ]-----------------
        let content;
        if (this.props.headerType === "header-projects-new-project") {
            content = <>{newProject}</>;
        } else if (this.props.headerType === "header-projects-content") {
            content = (
                <>
                    {statistics}
                    {this.props.projects.length > 0 ? projects : <Empty description="No projects"/>}
                    {this.props.projects.length > 0 && pagination}
                </>
            );
        } else if (this.props.headerType === "header-rename-project") {
            content = <>{renameProject}</>;
        } else if (this.props.headerType === "header-feed-configs-editor") {
            content = <>{feedConfigEditor}</>;
        } else if (this.props.headerType === "header-feed-rules-editor") {
            content = <>{feedRuleEditor}</>;
        }

        return (
            <Space direction="vertical" size="middle" style={{"width": "100%"}}>
                {content}
            </Space>
        );
    }
}