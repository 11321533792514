import React, {Component} from 'react';
import {Group, Image, Rect} from 'react-konva';
import Konva from 'konva';
import {Col, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {GlobalContext} from "../../contexts/GlobalContext.jsx";
import {updateGroupPosition} from "./ProjectPage"
import {updateImage} from "./AddLayerNav.jsx";
import ColorPicker from "./ColorPicker.jsx";
import errorImage from '../../data/error_image.svg';
import loadingImage from '../../data/loading_image.svg';


export function setImageSize(image_menu) {
    const target_group = image_menu.props.selectedShape.findAncestor('Group');
    const imgRect = target_group.findOne('Rect');

    imgRect.width(parseFloat(image_menu.widthInput.value));
    imgRect.height(parseFloat(image_menu.heightInput.value));
    target_group.rotation(parseFloat(image_menu.rotationInput.value));
    target_group.x(parseFloat(image_menu.xInput.value) + image_menu.context.defaultX);
    target_group.y(parseFloat(image_menu.yInput.value) + image_menu.context.defaultY);

    const mode = image_menu.modeInput.value;
    image_menu.props.selectedShape.setAttr('mode', mode);

    const opacity = parseFloat(image_menu.opacityInput.value);
    target_group.setAttr('saved_opacity', opacity);
    imgRect.opacity(mode === "scale" ? opacity : 1);
    image_menu.props.selectedShape.opacity(mode === "scale" ? 0 : opacity);

    imgRect.fill(mode === "scale" ? undefined : imgRect.getAttr('saved_fill'));
    image_menu.context.removeTransform();
    scaleImage(image_menu.props.selectedShape, imgRect);
    image_menu.context.selectLayer(image_menu.props.selectedShape.getAttr('layer_id'));
    updateGroupPosition(target_group);
}


export function scaleImage(imgObj, rectObj) {
    let hRatio, vRatio, min_ratio, max_ratio, centerShift_x, centerShift_y;
    const mode = imgObj.getAttr('mode');
    const defaultImageWidth = rectObj.getAttr('bgSizes').width;
    const defaultImageHeight = rectObj.getAttr('bgSizes').height;
    const rectWidth = rectObj.width();
    const rectHeight = rectObj.height();

    hRatio = rectWidth / defaultImageWidth;
    vRatio = rectHeight / defaultImageHeight;
    if (mode === 'stretch') {
        imgObj.x(0);
        imgObj.y(0);
        imgObj.width(rectWidth);
        imgObj.height(rectHeight);
    } else {
        min_ratio = Math.min(hRatio, vRatio);
        centerShift_x = (rectWidth - defaultImageWidth * min_ratio) / 2;
        centerShift_y = (rectHeight - defaultImageHeight * min_ratio) / 2;
        imgObj.x(centerShift_x);
        imgObj.y(centerShift_y);
        imgObj.width(defaultImageWidth * min_ratio);
        imgObj.height(defaultImageHeight * min_ratio);
    }

    max_ratio = Math.max(hRatio, vRatio);
    centerShift_x = (rectWidth - defaultImageWidth * max_ratio) / 2;
    centerShift_y = (rectHeight - defaultImageHeight * max_ratio) / 2;
    rectObj.fillPatternScale({
        x: max_ratio,
        y: max_ratio
    });
    rectObj.fillPatternOffset({
        x: -centerShift_x / max_ratio,
        y: -centerShift_y / max_ratio
    });
}


function updateImageSrc(image_menu) {
    const target_group = image_menu.props.selectedShape.findAncestor('Group');
    const imgRect = target_group.findOne('Rect');
    const img = target_group.findOne('Image');
    const layer_id = image_menu.props.selectedShape.getAttr('layer_id');
    updateImage(
        image_menu.context.app,
        layer_id,
        image_menu.srcInput.value,
        target_group.x(),
        target_group.y(),
        imgRect.width(),
        imgRect.height(),
        target_group.rotation(),
        false,
        target_group.getAttr('saved_opacity'),
        img.getAttr('mode'),
        imgRect.getAttr('saved_fill')
    )
}


export class StaticImageSettings extends Component {

    componentDidMount() {
        this.context.right_menu = this;
    }

    componentWillUnmount() {
        this.context.right_menu = undefined;
    }

    render() {
        const imgGroup = this.props.selectedShape.findAncestor('Group');
        const imgRect = imgGroup.findOne('Rect');
        const img = imgGroup.findOne('Image');
        return (
            <Form>
                <h2>Static Image Settings</h2>
                <Form.Group controlId="formStaticImage">
                    <InputGroup className="lg">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Text</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="text" name="setStaticImage"
                                     ref={input => this.srcInput = input}
                                     defaultValue={this.props.selectedShape.getAttr('image_src')}
                                     onChange={() => updateImageSrc(this)}
                        />
                    </InputGroup>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col className="mb-6">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>W</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="number" name="setStaticImageWidth"
                                             ref={input => this.widthInput = input}
                                             value={imgRect.width()}
                                             onChange={() => setImageSize(this)}
                                />
                            </InputGroup>
                        </Col>
                        <Col className="mb-6">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>H</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="number" name="setStaticImageHeight"
                                             ref={input => this.heightInput = input}
                                             value={imgRect.height()}
                                             onChange={() => setImageSize(this)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col className="mb-6">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>X</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="number" name="setStaticImageX"
                                             ref={input => this.xInput = input}
                                             value={imgGroup.x() - this.context.defaultX}
                                             onChange={() => setImageSize(this)}
                                />
                            </InputGroup>
                        </Col>
                        <Col className="mb-6">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Y</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="number" name="setStaticImageY"
                                             ref={input => this.yInput = input}
                                             value={imgGroup.y() - this.context.defaultY}
                                             onChange={() => setImageSize(this)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Rotation</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="number" name="setStaticImageRotation"
                                     ref={input => this.rotationInput = input}
                                     value={imgGroup.rotation()}
                                     onChange={() => setImageSize(this)}
                        />
                    </InputGroup>
                </Form.Group>
                {img.getAttr('mode') !== 'scale' &&
                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Bg color</InputGroup.Text>
                        </InputGroup.Prepend>
                        <ColorPicker obj={imgRect}/>
                    </InputGroup>
                </Form.Group>}
                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Opacity</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="number" min="0" max="1" step="0.01" name="setStaticImageOpacity"
                                     ref={input => this.opacityInput = input}
                                     value={imgGroup.getAttr('saved_opacity')}
                                     onChange={() => setImageSize(this)}
                        />
                    </InputGroup>
                </Form.Group>
                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="image-mode-select">Select mode:</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            as="select"
                            ref={input => this.modeInput = input}
                            value={this.props.selectedShape.getAttr('mode')}
                            onChange={() => setImageSize(this)}
                        >
                            <option>fit</option>
                            <option>scale</option>
                            <option>stretch</option>
                        </Form.Control>
                    </InputGroup>
                </Form.Group>
            </Form>
        );
    }
}


export class DynamicImageSettings extends Component {

    componentDidMount() {
        this.context.right_menu = this;
    }

    componentWillUnmount() {
        this.context.right_menu = undefined;
    }

    setImage = () => {
        const target = this.props.selectedShape;
        const target_group = this.props.selectedShape.findAncestor('Group');
        const target_rect = target_group.findOne('Rect');
        this.context.updateImage(
            target.getAttr('layer_id'),
            this.textInput.value,
            target_group.x(),
            target_group.y(),
            target_rect.width(),
            target_rect.height(),
            target_group.rotation(),
            true,
            target.getAttr('mode'),
            target_rect.getAttr('saved_fill'),
            target_group.getAttr('saved_opacity')
        );
    };

    render() {
        const imgGroup = this.props.selectedShape.findAncestor('Group');
        const imgRect = imgGroup.findOne('Rect');
        const img = imgGroup.findOne('Image');
        return (
            <Form>
                <h2>Dynamic Image Settings</h2>
                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="image-field-select">Select field:</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            as="select"
                            ref={input => this.textInput = input}
                            value={this.props.selectedShape.getAttr('dynamic')}
                            onChange={this.setImage}
                        >
                            {Object.keys(this.context.feed[this.context.currentFeedProduct]).map((field, i) => (
                                <option key={'image-field-option-' + i}>{field}</option>
                            ))}
                        </Form.Control>
                    </InputGroup>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col className="mb-6">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>W</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="number" name="setDynamicImageWidth"
                                             ref={input => this.widthInput = input}
                                             value={imgRect.width()}
                                             onChange={() => setImageSize(this)}
                                />
                            </InputGroup>
                        </Col>
                        <Col className="mb-6">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>H</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="number" name="setDynamicImageHeight"
                                             ref={input => this.heightInput = input}
                                             value={imgRect.height()}
                                             onChange={() => setImageSize(this)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col className="mb-6">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>X</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="number" name="setDynamicImageX"
                                             ref={input => this.xInput = input}
                                             value={imgGroup.x() - this.context.defaultX}
                                             onChange={() => setImageSize(this)}
                                />
                            </InputGroup>
                        </Col>
                        <Col className="mb-6">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Y</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="number" name="setDynamicImageY"
                                             ref={input => this.yInput = input}
                                             value={imgGroup.y() - this.context.defaultY}
                                             onChange={() => setImageSize(this)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Rotation</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="number" name="setDynamicImageRotation"
                                     ref={input => this.rotationInput = input}
                                     value={imgGroup.rotation()}
                                     onChange={() => setImageSize(this)}
                        />
                    </InputGroup>
                </Form.Group>
                {img.getAttr('mode') !== 'scale' &&
                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Bg color</InputGroup.Text>
                        </InputGroup.Prepend>
                        <ColorPicker obj={imgRect}/>
                    </InputGroup>
                </Form.Group>}

                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Opacity</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="number" min="0" max="1" step="0.001" name="setDynamicImageOpacity"
                                     ref={input => this.opacityInput = input}
                                     value={imgGroup.getAttr('saved_opacity')}
                                     onChange={() => setImageSize(this)}
                        />
                    </InputGroup>
                </Form.Group>
                <Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="image-mode-select">Select mode:</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            as="select"
                            ref={input => this.modeInput = input}
                            value={this.props.selectedShape.getAttr('mode')}
                            onChange={() => setImageSize(this)}
                        >
                            <option>fit</option>
                            <option>scale</option>
                            <option>stretch</option>
                        </Form.Control>
                    </InputGroup>
                </Form.Group>
            </Form>
        );
    }
}


export function getImageComponent(
    feed,
    dynamic,
    url,
    currentFeedProduct,
    enableTransform,
    handleDragEnd,
    handleTransformEnd,
    removeTransform,
    selectLayer,
    getKonvaObjectSize,
    layer_id,
    x,
    y,
    width,
    height,
    rotation,
    mode,
    fillColor,
    opacity
) {
    return <URLImage
        src={dynamic ? feed[currentFeedProduct][url] : url}
        onDragStart={enableTransform}
        onDragEnd={handleDragEnd}
        onTransformEnd={handleTransformEnd}
        removeTransform={removeTransform}
        selectLayer={selectLayer}
        getSize={getKonvaObjectSize}
        layer_id={layer_id}
        dynamic={dynamic ? url : false}
        x={x}
        y={y}
        width={width}
        height={height}
        rotation={rotation}
        mode={mode}
        fillColor={fillColor}
        opacity={opacity}
        currentFeedProduct={currentFeedProduct}
    />
}


// custom component that will handle loading image from url
// you may add more logic here to handle "loading" state
// or if loading is failed
// VERY IMPORTANT NOTES:
// at first we will set image state to null
// and then we will set it to native image instance when it is loaded
class URLImage extends Component {

    constructor(props) {
        super(props);

        this.loadingImage = new window.Image();
        this.loadingImage.src = loadingImage;
        this.state = {
            image: this.loadingImage,
        };
    }


    componentDidMount() {
        const image = this.imageNode;
        const rect = image.findAncestor('Group').findOne('Rect');
        this.loaderAnimation = new Konva.Animation(function (frame) {
                const angleDiff = (frame.timeDiff * 120) / 1000;
                image.rotate(angleDiff);
                image.offsetX(image.width() / 2);
                image.offsetY(image.height() / 2);
                image.x(rect.width() / 2);
                image.y(rect.height() / 2);
            }, image.getLayer());
        this.setState({
            backupRotation: image.rotation(),
            backupOffset: image.offset(),
            backupPosition: image.position(),
        }, () => {
            this.loadImage();
        });

    }

    componentDidUpdate(oldProps) {
        if (oldProps.src !== this.props.src || oldProps.currentFeedProduct !== this.props.currentFeedProduct) {
            this.imageNode.width(undefined);
            this.imageNode.height(undefined);
            const restore_transform = this.props.removeTransform(this.imageNode.findAncestor('Group'));
            this.setState({
                    image: this.loadingImage,
                    restore_transform: restore_transform
                },
                () => {

                    this.setState({
                        bgSizes: {
                            width: this.imageNode.width(),
                            height: this.imageNode.height()
                        }
                    }, () => {
                        scaleImage(this.imageNode, this.imageRect);
                        this.loadImage();
                    });
                }
            );
        }
    }

    componentWillUnmount() {
        this.image.removeEventListener('load', this.handleLoad);
    }

    loadImage() {
        const group = this.imageNode.findAncestor('Group');
        group.draggable(false);

        this.loaderAnimation.start();

        // save to "this" to remove "load" handler on unmount
        if (this.image && !this.image.complete) {
            this.image.src = '';
            this.image.removeEventListener('load', this.handleLoad);
            this.image.removeEventListener('error', this.handleError);
        }
        this.image = new window.Image();
        this.image.src = this.props.src;
        this.image.addEventListener('error', this.handleError);
        this.image.addEventListener('load', this.handleLoad);
    }

    handleError = () => {
        this.image.src = errorImage;
        alert("Invalid image URL!")
    };

    handleLoad = () => {
        // after setState react-konva will update canvas and redraw the layer
        // because "image" property is changed
        this.imageNode.position(this.state.backupPosition);
        this.imageNode.rotation(this.state.backupRotation);
        this.imageNode.offset(this.state.backupOffset);
        this.imageNode.width(undefined);
        this.imageNode.height(undefined);

        this.setState({
            image: this.image
        }, () => {
            this.loaderAnimation.stop();
            const group = this.imageNode.findAncestor('Group');
            group.draggable(true);

            this.setState({
                    bgSizes: {
                        width: this.imageNode.width(),
                        height: this.imageNode.height()
                    }
                },
                () => {
                    scaleImage(this.imageNode, this.imageRect);
                    this.props.getSize(this.imageNode);
                    if (this.state.restore_transform) {
                        this.props.selectLayer(this.props.layer_id)
                    }
                })
        });


        // if you keep same image object during source updates
        // you will have to update layer manually:
        // this.imageNode.getLayer().batchDraw();
    };

    render() {
        const fillColor = this.props.fillColor || "rgba(0,0,0,0)";
        return (
            <Group
                draggable
                onClick={this.props.onClick}
                onDragStart={this.props.onDragStart}
                onDragEnd={this.props.onDragEnd}
                onTransformEnd={this.props.onTransformEnd}
                listening={true}
                rotation={this.props.rotation || 0}
                layer_id={this.props.layer_id}
                name={'group-' + this.props.layer_id}
                x={this.props.x}
                y={this.props.y}
                saved_opacity={this.props.opacity || 1}
            >
                <Rect
                    fill={this.props.mode === "scale" ? undefined : fillColor}
                    saved_fill={fillColor}
                    width={this.props.width || 100}
                    height={this.props.height || 50}
                    name='no-transform'
                    listening={true}
                    ref={node => {
                        this.imageRect = node;
                    }}
                    fillPatternImage={this.state.image}
                    fillPatternRepeat={'no-repeat'}
                    bgSizes={this.state.bgSizes}
                    opacity={this.props.mode === "scale" ? this.props.opacity : 1}
                />
                <Image
                    image={this.state.image}
                    image_src={this.props.src}
                    layer_id={this.props.layer_id}
                    name='no-transform-image'
                    ref={node => {
                        this.imageNode = node;
                    }}
                    dynamic={this.props.dynamic}
                    opacity={this.props.mode === "scale" ? 0 : this.props.opacity}
                    mode={this.props.mode || "fit"}
                />
            </Group>
        );
    }
}

StaticImageSettings.contextType = GlobalContext;
DynamicImageSettings.contextType = GlobalContext;
export default URLImage;