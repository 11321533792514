import React from "react";
import {PageHeader} from "antd";

export const ProjectsHeader = props => {
    return (
        <PageHeader
            ghost={false}
            title={props.data.title}
            style={{boxShadow: "-2px 2px 10px lightgray"}}
            breadcrumb={props.data.routes}
            extra={props.data.extra}
        />
    );
}