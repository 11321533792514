import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFont, faImages } from '@fortawesome/free-solid-svg-icons'
import {Button, ButtonGroup, Form, FormControl, InputGroup, Modal} from 'react-bootstrap'
import {GlobalContext} from '../../contexts/GlobalContext.jsx';
import {findWithAttr} from './ProjectPage.jsx';
import {getImageComponent} from "./ImageFromUrl.jsx";
import {getTextComponent} from "./TextAdaptive.jsx";

export function addImage(app, url, dynamic) {
    let new_layers = [...app.state.layers];
    // Adding new top layer to the beginning of the layers array
    let new_id;
    if (new_layers.length) {
        new_id = Math.max.apply(Math, new_layers.map(function (o) {
            return o.key_id;
        })) + 1
    } else {
        new_id = 0
    }
    const i = new_layers.length;
    new_layers.push({
        component:
            () => getImageComponent(
                app.state.feed,
                dynamic,
                url,
                app.state.currentFeedProduct,
                app.state.enableTransform,
                app.state.handleDragEnd,
                app.state.handleTransformEnd,
                app.state.removeTransform,
                app.state.selectLayer,
                app.state.getKonvaObjectSize,
                i,
                app.state.defaultX,
                app.state.defaultY
            ),
        id: i,
        content: `Layer ${i + 1} (Image)`,
        x: 0,
        y: 0,
        rotation: 0,
        type: 'Image',
        dynamic: dynamic,
        url: url,
        key_id: new_id
    });
    app.setState({
        layers: new_layers,
    });
}

export function updateImage(app, layer_id, url, x, y, width, height, rotation, dynamic, opacity, mode, saved_fill) {
    let new_layers = [...app.state.layers];
    const layer_index = findWithAttr(new_layers, 'id', layer_id);
    new_layers[layer_index].component =
        () => getImageComponent(
            app.state.feed,
            dynamic,
            url,
            app.state.currentFeedProduct,
            app.state.enableTransform,
            app.state.handleDragEnd,
            app.state.handleTransformEnd,
            app.state.removeTransform,
            app.state.selectLayer,
            app.state.getKonvaObjectSize,
            layer_id,
            x,
            y,
            width,
            height,
            rotation,
            mode,
            saved_fill,
            opacity
        );
    new_layers[layer_index].id = layer_id;
    new_layers[layer_index].content = `Layer ${layer_id + 1} (${new_layers[layer_index].type})`;
    new_layers[layer_index].dynamic = dynamic;
    new_layers[layer_index].url = url;
    app.setState({
        layers: new_layers,
    });
}

export function updateText(app, layer_id, text, x, y, width, height, rotation, dynamic, opacity, fill, bgFill, align, verticalAlign, fontFamily, textDecoration, fontStyle, fontVariant, minFontSize, maxFontSize, maxLines, cutText, regexpMatch, regexpReplace, placeholderStatus, placeholderText, perfectToggle, substrateToggle, substrateFill, substrateTop, substrateRight, substrateBottom, substrateLeft, substrateCornerRadiusTL, substrateCornerRadiusTR, substrateCornerRadiusBR, substrateCornerRadiusBL) {
    let new_layers = [...app.state.layers];
    const layer_index = findWithAttr(new_layers, 'id', layer_id);
    new_layers[layer_index].component =
        () => getTextComponent(
            app.state.feed,
            dynamic,
            text,
            app.state.currentFeedProduct,
            app.state.enableTransform,
            app.state.handleDragEnd,
            app.state.handleTransformEnd,
            app.state.removeTransform,
            app.state.selectLayer,
            app.state.getKonvaObjectSize,
            layer_id,
            x,
            y,
            width,
            height,
            rotation,
            fill,
            bgFill,
            align,
            verticalAlign,
            fontFamily,
            textDecoration,
            fontStyle,
            fontVariant,
            opacity,
            minFontSize,
            maxFontSize,
            maxLines,
            cutText,
            regexpMatch,
            regexpReplace,
            placeholderStatus,
            placeholderText,
            perfectToggle,
            substrateToggle,
            substrateFill,
            substrateTop,
            substrateRight,
            substrateBottom,
            substrateLeft,
            substrateCornerRadiusTL,
            substrateCornerRadiusTR,
            substrateCornerRadiusBR,
            substrateCornerRadiusBL
        );
    new_layers[layer_index].id = layer_id;
    new_layers[layer_index].content = `Layer ${layer_id + 1} (${new_layers[layer_index].type})`;
    new_layers[layer_index].dynamic = dynamic;
    new_layers[layer_index].text = text;
    app.setState({
        layers: new_layers,
    });
}

export function addText(app, text, dynamic) {
    let new_layers = [...app.state.layers];
    // Adding new top layer to the beginning of the layers array
    let new_id;
    if (new_layers.length) {
        new_id = Math.max.apply(Math, new_layers.map(function (o) {
            return o.key_id;
        })) + 1
    } else {
        new_id = 0
    }
    const i = new_layers.length;
    new_layers.push({
        component: () => getTextComponent(
            app.state.feed,
            dynamic,
            text,
            app.state.currentFeedProduct,
            app.state.enableTransform,
            app.state.handleDragEnd,
            app.state.handleTransformEnd,
            app.state.removeTransform,
            app.state.selectLayer,
            app.state.getKonvaObjectSize,
            i,
            app.state.defaultX,
            app.state.defaultY
            ),
        id: new_layers.length,
        content: `Layer ${new_layers.length + 1} (Text)`,
        x: 0,
        y: 0,
        rotation: 0,
        type: 'Text',
        dynamic: dynamic,
        text: text,
        key_id: new_id
    });
    app.setState({
        layers: new_layers,
    });
}

class AddLayerNav extends Component {
    state = {
        showModal: false,
        dynamicLayer: false,
        currentType: undefined
    };

    componentDidMount() {
        this.setState({
            dynamicForm: () => <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">Select field:</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control as="select" ref={input => this.textInput = input}>
                    {Object.keys(this.context.feed[this.context.currentFeedProduct]).map((field, i) => (
                        <option key={'field-option-' + i}>{field}</option>
                    ))}
                </Form.Control>
            </InputGroup>
        });
    }

    constructor(props, context) {
        super(props, context);


        this.imageConfig = {
            submit: (e) => {
                e.preventDefault();
                this.context.addImage(this.textInput.value, this.state.dynamicLayer);
                this.setState({showModal: false});
            },
            form: () =>
                <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">Image url:</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    placeholder="url"
                    aria-label="url"
                    aria-describedby="basic-addon1"
                    defaultValue='https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png'
                    ref={input => this.textInput = input}
                />
            </InputGroup>,
        };

        this.textConfig = {
            submit: (e) => {
                e.preventDefault();
                this.context.addText(this.textInput.value, this.state.dynamicLayer);
                this.setState({showModal: false});
            },
            form: () =>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Text:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        placeholder="Text"
                        aria-label="Text"
                        aria-describedby="basic-addon1"
                        ref={input => this.textInput = input}
                    />
                </InputGroup>
        };

        this.handleClose = () => this.setState({showModal: false, dynamicLayer: false, currentType: undefined});
        this.handleShow = (type) => {
            if (type === 'image') {
                this.setState({showModal: true, configForm: this.imageConfig, dynamicLayer: false, currentType: type})
            }
            if (type === 'text') {
                this.setState({showModal: true, configForm: this.textConfig, dynamicLayer: false, currentType: type})
            }
        };
    }

    render() {
        return <div className='add-layer'>
            <Button data-type="image" variant="primary" size="lg" block onClick={() => this.handleShow("image")}>
                <FontAwesomeIcon icon={faImages} data-type="image" onClick={() => this.handleShow("image")}/>
            </Button>
            <Button data-type="text" variant="primary" size="lg" block onClick={() => this.handleShow("text")}>
                <FontAwesomeIcon icon={faFont} data-type="text" onClick={() => this.handleShow("text")}/>
            </Button>
            {this.state.configForm !== undefined &&
            <>
                <Modal show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add { this.state.dynamicLayer ? 'dynamic' : 'static' } { this.state.currentType }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="d-flex flex-column">
                        <ButtonGroup aria-label="Basic example" size="lg">
                            <Button
                                variant="secondary"
                                className={this.state.dynamicLayer ? '' : 'active'}
                                onClick={()=>this.setState({dynamicLayer: false})}
                            >Static</Button>
                            <Button
                                variant="secondary"
                                className={this.state.dynamicLayer ? 'active' : ''}
                                onClick={()=>this.setState({dynamicLayer: true})}
                            >Dynamic</Button>
                        </ButtonGroup>
                        <br/>
                        <Form onSubmit={this.state.configForm.submit}>
                            {this.state.dynamicLayer ? this.state.dynamicForm() : this.state.configForm.form()}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.state.configForm.submit}>
                            Add
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            }
        </div>;
    }
}

AddLayerNav.contextType = GlobalContext;
export default AddLayerNav;
