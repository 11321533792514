import {Avatar, Dropdown, Layout, Menu, Space} from "antd";
import React from "react";
import {UserOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";


export const Header = props => {
    const {Header} = Layout;
    const menu = props.user ?
        <Menu>
            <Menu.Item>
                <Link to={"/profile"}>
                    Profile
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to={"/settings"}>
                    Settings
                </Link>
            </Menu.Item>
            <Menu.Item>
                <span onClick={() => props.logOut()}>
                    Exit
                </span>
            </Menu.Item>
        </Menu>
        :
        <Menu>
            <Menu.Item>
                <Link to={"/login"}>
                    Login
                </Link>
            </Menu.Item>
        </Menu>;

    return (
        <Header>
            <Menu theme="dark" style={{float: "right"}} mode="horizontal">
                <Dropdown overlay={menu} placement="bottomCenter" arrow>
                    <Space>
                        <Avatar icon={<UserOutlined/>}/>{props.user ? props.user.username : "Guest"}
                    </Space>
                </Dropdown>
            </Menu>
        </Header>
    );
}
