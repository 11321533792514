import React, {Component} from 'react';
import KonvaApp, {
    changeCanvasSize,
    downloadStage,
    importStage,
    getKonvaObjectSize,
    handleCustomCanvasSize,
    handleDragEnd,
    handleTransformEnd,
    selectCanvasSize,
    toggleOutlineArea
} from './KonvaApp.jsx';
import {
    changeProduct,
    changeStageZoom,
    scrollStageZoom
} from './KonvaControls.jsx';
import LayersList, {onListDragEnd} from './LayersList.jsx';
import AddLayerNav, {addImage, addText, updateImage, updateText} from './AddLayerNav.jsx';
import RightFilter, {deleteLayer} from './RightFilter.jsx';
import {Col, Container, Row} from 'react-bootstrap';
import {GlobalContext} from '../../contexts/GlobalContext.jsx';
import {enableTransform, removeTransform, selectLayer} from './Transformer.jsx';
import {withRouter} from "react-router-dom";

// TODO: Fix design load and import stage
// TODO: Switch bootstrap to ant.design (in process)

export const headerHeight = 56;

export function findWithAttr(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

export function updateGroupPosition(target_group) {
    const box = target_group.getClientRect({relativeTo: target_group});
    target_group.setAttr('real_x', box.x);
    target_group.setAttr('real_y', box.y);
    target_group.setAttr('real_width', box.width);
    target_group.setAttr('real_height', box.height);
}

class ProjectPage extends Component {
    api = this.props.backend;

    deleteLayer = layer_id => deleteLayer(this, layer_id);

    getKonvaObjectSize = obj => getKonvaObjectSize(this, obj);

    handleDragEnd = e => handleDragEnd(this, e);

    handleTransformEnd = e => handleTransformEnd(this, e);

    addImage = (url, dynamic) => addImage(this, url, dynamic);

    updateImage = (layer_id, url, x, y, width, height, rotation, dynamic, opacity, mode, saved_fill) => updateImage(this, layer_id, url, x, y, width, height, rotation, dynamic, opacity, mode, saved_fill);

    updateText = (layer_id, url, x, y, width, height, rotation, dynamic, opacity, fill, bgFill, align, verticalAlign, fontFamily, textDecoration, fontStyle, fontVariant, minFontSize, maxFontSize, maxLines, cutText, regexpMatch, regexpReplace, placeholderStatus, placeholderText, perfectToggle, substrateToggle, substrateFill, substrateTop, substrateRight, substrateBottom, substrateLeft, substrateCornerRadiusTL, substrateCornerRadiusTR, substrateCornerRadiusBR, substrateCornerRadiusBL) => updateText(this, layer_id, url, x, y, width, height, rotation, dynamic, opacity, fill, bgFill, align, verticalAlign, fontFamily, textDecoration, fontStyle, fontVariant, minFontSize, maxFontSize, maxLines, cutText, regexpMatch, regexpReplace, placeholderStatus, placeholderText, perfectToggle, substrateToggle, substrateFill, substrateTop, substrateRight, substrateBottom, substrateLeft, substrateCornerRadiusTL, substrateCornerRadiusTR, substrateCornerRadiusBR, substrateCornerRadiusBL);

    addText = (text, dynamic) => addText(this, text, dynamic);

    onListDragEnd = result => onListDragEnd(this, result);

    toggleOutlineArea = v => toggleOutlineArea(this, v);

    changeCanvasSize = e => changeCanvasSize(this, e);

    enableTransform = e => enableTransform(this, e);

    selectLayer = layer_num => selectLayer(this, layer_num);

    removeTransform = el => removeTransform(this, el);

    changeStageZoom = zoomDirection => changeStageZoom(this, zoomDirection);

    scrollStageZoom = e => scrollStageZoom(this, e);

    handleSelect = selectedOptionNumber => selectCanvasSize(this, selectedOptionNumber);

    changeProduct = direction => changeProduct(this, direction);

    handleCustomCanvasSize = e => handleCustomCanvasSize(this, e);

    downloadStage = () => downloadStage(this);

    importStage = (json_data, parse) => importStage(this, json_data, parse);

    loadFeed = projectId => {
        this.fetchProject(projectId).then(
            result => this.fetchFeed(result),
            err => {
                console.error(err);
                alert(err.message);
            }
        )
    }

    fetchFeed = data => {
        const feedUrl = data.feed.feed_url;
        const feedId = data.feed.id;
        return this.api.downloadFeed(feedUrl).then(
            result => {
                console.log("fetchFeed", result);
                this.setState(
                    {
                        feed: result,
                        feed_id: feedId
                    },
                    () => {
                        if (this.state.designId !== undefined) {
                            this.importStage(this.state.design_json)
                        }
                    });
                return result
            },
            err => console.error(err.message)
    )

    };

    fetchProject = projectId => {
        return this.api.fetchProject(projectId)
            .then(
                data => data,
                err => {
                    console.error(err);
                    window.location.href = '/'
                }
            )
    };

    loadProject = projectId => {
        this.fetchProject(projectId)
            .then(result => {
                const design = result.designs.filter(d => d.id === +this.state.designId)[0];
                this.setState(
                    {
                        design_json: design.design_json,
                        designName: design.name
                    },
                    () => this.fetchFeed(result));

            })
    };

    state = {
        feed: [],
        app: this,
        // ----------------------{ Project settings }----------------------
        designName: "",
        dataSource: undefined,
        feedId: undefined,
        designId: this.props.match.params.design_id,
        projectId: this.props.match.params.project_id,
        canvasSize: {width: 300, height: 300},  // default canvas size for correct render
        currentFeedProduct: 0,

        // ----------------------{ Behaviour,  Data }----------------------
        projectData: undefined,                                          // stage JSON for restore project

        canvasSizesData: undefined,
        canvasSizesSelectedOption: 0,

        outlineVisiblity: false,

        displayColorPicker: false,

        // ----------------------{       AJAX       }----------------------
        ajaxErrorCanvasSizes: null,

        // ----------------------{     Functions    }----------------------
        handleSelect: this.handleSelect,                                 // for Canvas sizes and Data sources dropdowns
        toggleOutlineArea: this.toggleOutlineArea,
        addImage: this.addImage,
        updateImage: this.updateImage,
        updateText: this.updateText,
        onListDragEnd: this.onListDragEnd,
        addText: this.addText,
        getKonvaObjectSize: this.getKonvaObjectSize,
        enableTransform: this.enableTransform,
        removeTransform: this.removeTransform,
        changeCanvasSize: this.changeCanvasSize,
        zoomStage: this.changeStageZoom,
        scrollStageZoom: this.scrollStageZoom,
        handleCustomCanvasSize: this.handleCustomCanvasSize,
        changeProduct: this.changeProduct,
        selectLayer: this.selectLayer,
        deleteLayer: this.deleteLayer,
        handleTransformEnd: this.handleTransformEnd,
        handleDragEnd: this.handleDragEnd,
        downloadStage: this.downloadStage,
        importStage: this.importStage,

        // ----------------------{      Layers      }----------------------
        layers: []
    };

    componentDidMount() {
        this.state.designId !== undefined ?
            this.loadProject(this.state.projectId)
            :
            this.loadFeed(this.state.projectId);

        this.setState({
            isLoadedCanvasSizes: true,
            canvasSizes: [
                {
                    id: 1,
                    platform: "Facebook",
                    format: "Beautiful ad",
                    x: 1080,
                    y: 523
                },
                {
                    id: 2,
                    platform: "Facebook",
                    format: "Post",
                    x: 1080,
                    y: 900
                }
            ]
        })
    }

    render() {
        const content_height = window.innerHeight - headerHeight;
        return (
            <Container fluid={true}>
                <Row style={{height: content_height}}>
                    <GlobalContext.Provider value={this.state}>
                        <Col xs={3} className='no-padding'>
                            <AddLayerNav/>
                            <LayersList/>
                        </Col>
                        <KonvaApp headerHeight={headerHeight} backend={this.api}/>
                        <RightFilter/>
                    </GlobalContext.Provider>
                </Row>
            </Container>
        );
    }
}

export default withRouter(ProjectPage);
