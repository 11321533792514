import React from "react";
import {ChromePicker} from "react-color";
import {GlobalContext} from "../../contexts/GlobalContext.jsx";
import {scaleText} from "./TextAdaptive.jsx";

class ColorPicker extends React.Component {
    state = {
        display: false,
        color: this.props.obj.getAttr('saved_fill') || this.props.obj.fill()
    };

    componentDidUpdate(oldProps) {
        if (this.props.obj.getAttr('saved_fill') !== oldProps.obj.getAttr('saved_fill')) {
            this.setState(
                {
                    color: this.props.obj.getAttr('saved_fill') || this.props.obj.fill()
                }
            )
        }
    }

    handleClick = () => {
        this.setState({display: !this.state.display});
    };

    handleClose = () => {
        this.setState({display: false});
        this.forceUpdate();
    };

    handleChange = (color) => {
        this.setState({color: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`});
        this.props.obj.setAttr('fill', this.state.color);
        this.props.obj.setAttr('saved_fill', this.state.color);
        this.forceUpdate();
        this.props.obj.getLayer().batchDraw();
        let txtGroup = this.props.obj.findAncestor('Group');
        if (txtGroup && txtGroup.findOne('Text')) {
            scaleText(txtGroup.findOne('Text'));
        }
        // TODO: move handler to props
        // if (this.props.handler) {
        //     this.props.handler()
        // }
    };

    render() {

        const styles = {
            colorPickerColor: {
                width: "100%",
                height: "100%",
                background: this.state.color
            },
            colorPickerSwatch: {
                display: 'inline-block',
                cursor: 'pointer',
                padding: 5,
            },
            colorPickerPopOver: {
                position: 'absolute',
                zIndex: 10,
                left: "10%",
                top: "10%"
            },
            colorPickerCover: {
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            }
        };

        return <>
            <div style={styles.colorPickerSwatch} className="form-control" onClick={this.handleClick}>
                <div style={styles.colorPickerColor}/>
            </div>
            {
                this.state.display && <div style={styles.colorPickerPopOver}>
                    <div style={styles.colorPickerCover} onClick={this.handleClose}/>
                    <ChromePicker color={this.state.color} onChange={this.handleChange}/>
                </div>
            }
        </>
    }
}

ColorPicker.contextType = GlobalContext;
export default ColorPicker;