import {Layout, Menu} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import React from "react";

const {SubMenu} = Menu;
const {Sider} = Layout;

export const SidebarMenu = props => {
    return (
        <Sider collapsible collapsed={props.collapsed} onCollapse={props.onCollapse}>
            <Menu theme="dark" selectedKeys={[props.currentPage]} mode="inline">
                <SubMenu key="sub1" icon={<UserOutlined/>} title="DOT">
                    <Menu.Item key="menu-projects">
                        <Link to="/projects">Projects</Link>
                    </Menu.Item>
                    <Menu.Item key="menu-library">
                        <Link to="/library">Library</Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu key="sub2" icon={<UserOutlined/>} title="Image API">
                    <Menu.Item key="menu-templates">
                        <Link to="/templates">Templates</Link>
                    </Menu.Item>
                </SubMenu>
            </Menu>
        </Sider>
    );
}
