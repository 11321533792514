import React, {Component, useState} from 'react';
import {Button, Card, Col, Empty, Input, message, Popconfirm, Row, Space, Switch, Tag, Tooltip} from 'antd';
import {WarningOutlined} from "@ant-design/icons";

// TODO: Make a dict with fields for autofill for Facebook pattern, yandex pattern and etc

export class FeedRules extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        };
    }

    onClickNewFeedRule = () => {
        const actionFromData = {
            data: this.state.data,
            title: "New feed rule",
            method: "create"
        };
        this.props.handleHeaderUpdate({
            headerType: "header-feed-rules-editor",
            headerBreadcrumbIndex: "DOT",
            headerBreadcrumbFirst: "Feed Rules",
            headerBreadcrumbSecond: actionFromData.title,
            headerTitle: actionFromData.title,
            headerExtra: [<Button key="btn-back-in-new-feed-rules" onClick={() => this.props.back()}>Back</Button>],
            actionFrom: actionFromData
        });
    }

    onClickDeleteFeedRule = feedRuleId => {
        const key = "message-delete-feed-rule";
        const url = `/projects/${this.state.data.id}/rules/${feedRuleId}/`;
        message.loading({
            content: "Deleting feed rule...",
            key: key,
            duration: 3
        });
        this.props.fetch(
            url,
            {method: "DELETE"}
        ).then(
            () => {
                message.success({
                    content: "Feed rule has been deleted!",
                    key: key,
                    duration: 3
                });
                const newData = this.state.data;
                newData.rules = newData.rules.filter(item => item.id !== feedRuleId)
                this.setState({
                        data: newData
                    },
                    () => this.props.updateTabCounter("rulesCount", newData.rules.length));

            },
            () => {
                message.error({
                    content: "Error!",
                    key: key,
                    duration: 3
                });
            }
        )
    }

    render() {

        const feedRules = (
            this.state.data.rules.length > 0 ?
                this.state.data.rules.map(rule => <FeedRuleCard
                    data={rule}
                    handleHeaderUpdate={this.props.handleHeaderUpdate}
                    delete={this.onClickDeleteFeedRule}
                />)
                :
                <Empty description="No feed configs"/>
        )

        const onNewFeedRule = (
            <Button type="primary" onClick={() => this.onClickNewFeedRule()}>New feed rule</Button>
        );

        return (
            <>
                {onNewFeedRule}
                <Space direction="vertical" style={{width: "100%"}}>
                    {feedRules}
                </Space>
            </>
        )
            ;
    }
}


const FeedRuleCard = props => {

    // TODO: Waiting for need data from backend
    const validation = (
        <Space>
            {
                props.data.valid ?
                    <Tag key={`tag-${props.data.id}-valid`} color="green">Valid</Tag>
                    :
                    <Tag key={`tag-${props.data.id}-valid`} color="red">Not valid</Tag>
            }
            {
                !props.data.valid &&
                <Tooltip title="A list of errors...">
                    <WarningOutlined style={{color: "red"}}/>
                </Tooltip>
            }

        </Space>
    )

    const onClickEditFeedRule = () => {
        const actionFromData = {
            data: props.data,
            title: "Edit feed rule",
            method: "edit"
        };
        props.handleHeaderUpdate({
            headerType: "header-feed-rules-editor",
            headerBreadcrumbIndex: "DOT",
            headerBreadcrumbFirst: "Feed Rules",
            headerBreadcrumbSecond: actionFromData.title,
            headerTitle: actionFromData.title,
            headerExtra: [<Button key="btn-back-in-feed-rules-editor" onClick={() => this.props.back()}>Back</Button>],
            actionFrom: actionFromData
        });
    };

    const onDeleteCancel = () => {
        message.info({
            content: "Cancelled.",
            duration: 3
        })
    }

    return (
        <Card
            bordered={true}
            title={<b>{props.data.name}</b>}
            extra={
                <Space>
                    <Button onClick={onClickEditFeedRule}>Edit</Button>
                    <Popconfirm
                        title="Are you sure to delete this feed config?"
                        onConfirm={() => props.delete(props.data.id)}
                        onCancel={onDeleteCancel}
                        okText="Yes, I'm sure"
                        cancelText="No"
                    >
                        <Button danger type="primary">Delete</Button>
                    </Popconfirm>
                </Space>
            }
        >
            <Row key={`template-info-${props.data.id}`}>
                <Col span={24}>
                    <Space direction="vertical">
                        <p><b>Created:</b> {new Date(props.data.created).toLocaleString()}</p>
                        <p>{validation}</p>
                        <p><Tag key={`tag-${props.data.id}-not-use`} color="orange">Not in use</Tag></p>
                    </Space>
                </Col>
            </Row>
        </Card>
    );
}


export const FeedRuleEditor = props => {

    const [feedRuleName, setFeedRuleName] = useState(
        props.actionFrom.method === "create" ?
            "" : props.actionFrom.data.name
    );
    const [feedRuleFileName, setFeedRuleFileName] = useState(
        props.actionFrom.method === "create" ?
            "" : props.actionFrom.data.rule_json.filename
    );
    const [ruleStatus, setRuleStatus] = useState(
        props.actionFrom.method === "create" ?
            true : props.actionFrom.data.active
    )

    const onSwitchStatus = () => {
        setRuleStatus(!ruleStatus);
    }

    const onSaveFeedRule = () => {
        const key = "message-new-feed-rule";
        const url = props.actionFrom.method === "create" ?
            `/projects/${props.actionFrom.data.id}/rules/`
            :
            `/projects/${props.actionFrom.data.project}/rules/${props.actionFrom.data.id}/`
        message.loading({
            content: props.actionFrom.method === "create" ?
                "Creating feed rule..."
                :
                "Sending updates..."
            ,
            key: key,
            duration: 3
        });
        props.fetch(
            url,
            {
                method: props.actionFrom.method === "create" ?
                    "POST" : "PUT"
                ,
                body: JSON.stringify({
                    name: feedRuleName,
                    designs: "test",
                    outputs: "test",
                    rule_json: "test",
                    active: ruleStatus
                })
            }
        ).then(
            () => {
                message.success({
                    content: props.actionFrom.method === "create" ?
                        "New feed rule has been created!"
                        :
                        "Feed rule has been updated!"
                    ,
                    key: key,
                    duration: 3
                });
                setTimeout(() => props.back(), 1000);
            },
            err => {
                message.error({
                    content: "Error!",
                    key: key,
                    duration: 3
                });
            }
        )
    };

    const onChangeInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === "feed-rule-name") {
            setFeedRuleName(value);
        } else if (name === "feed-rule-file-name") {
            setFeedRuleFileName(value);
        }
    }

    const rule = (
        <>
        </>
    );

    // TODO: Make a rule builder
    // TODO: Object creation after Save button
    return (

        <Card
            bordered={false}
            style={{boxShadow: "-2px 2px 10px lightgray"}}
            // title={<b>{props.actionFrom.title}</b>}
            actions={[
                <span onClick={() => props.back()}>Cancel</span>,
                <span onClick={onSaveFeedRule}>Save</span>
            ]}
        >
            <Space direction="vertical" size="large" style={{width: "100%"}}>
                <Row key="feed-rule-name">
                    <Col span={24}>
                        <h6>Feed rule name</h6>
                        <Input name="feed-rule-name" value={feedRuleName} onChange={onChangeInput}/>
                    </Col>
                </Row>
                <Row key="feed-rule-file-name">
                    <Col span={24}>
                        <h6>File name</h6>
                        <Input name="feed-rule-file-name" value={feedRuleFileName} onChange={onChangeInput}/>
                    </Col>
                </Row>
                <Row key="text">
                    <Col span={24}>
                        Products are added to the feed based on set of rules. Note that rules are applied using “else
                        if” logic meaning that product can be subject to only one rule (first in the list).
                    </Col>
                </Row>
                <Row key={"status"}>
                    <Col span={24}>
                        <h6>Status</h6>
                        <Switch defaultChecked onChange={onSwitchStatus} />
                    </Col>
                </Row>
                <Row key="buttons">
                    <Col span={24}>
                        {
                            // TODO: Add onClick for button
                        }
                        <Button>Add Rule</Button>
                    </Col>
                </Row>

                <Row key="rule-sets">
                    <Col span={24}>
                        {rule}
                    </Col>
                </Row>
            </Space>
        </Card>
    );
}
