import React, {Component} from "react";
import {Button, Card, Col, Image, Pagination, Row, Space, Tag, Tooltip} from "antd";
import {Link} from "react-router-dom";
import {WarningOutlined} from "@ant-design/icons";

export class Templates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Pagination
            pageSize: 5,
            pageFrom: 0,
            pageTo: 5,
            currentPage: 1,
        };
    }

    onPageChange = value => {
        this.setState({
            pageFrom: (value - 1) * this.state.pageSize,
            pageTo: value * this.state.pageSize,
            currentPage: value
        });
    };

    itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <span>Previous templates</span>;
        }
        if (type === 'next') {
            return <span>Next templates</span>;
        }
        return originalElement;
    };

    render() {

        const urlNewTemplate = `/project/${this.props.projectId}/design/new`;

        const templateMenu = (
            <Row>
                <Col>
                    <Space>
                        <Button type="primary"><Link to={urlNewTemplate}>New template</Link></Button>
                    </Space>
                </Col>
            </Row>
        );

        const templates = this.props.data
            .slice(this.state.pageFrom, this.state.pageTo)
            .map(template => <TemplateCard
                key={`template-card-${template.name.replace(" ", "-")}`}
                data={template}/>
            )

        const pagination = (
            <Row type="flex" justify="center" align="middle" style={{padding: 15}} key="templates-pagination">
                <Col>
                    <Pagination
                        current={this.state.currentPage}
                        pageSize={this.state.pageSize}
                        showQuickJumper
                        itemRender={this.itemRender}
                        onChange={this.onPageChange}
                        total={this.props.data.length}
                    />
                </Col>
            </Row>
        );

        return (
            <>
                {templateMenu}
                <Space direction="vertical" style={{width: "100%"}}>
                    {templates}
                </Space>
                {
                    this.props.data.length > 5 && pagination
                }
            </>
        );
    }
}


const TemplateCard = props => {

    const leftColStyle = {
        width: "30%"
    }

    const rightColStyle = {
        width: "70%"
    }

    // TODO: Waiting for need data from backend
    const validation = (
        <Space>
            {
                props.data.valid ?
                    <Tag key={`tag-${props.data.id}-valid`} color="green">Valid</Tag>
                    :
                    <Tag key={`tag-${props.data.id}-valid`} color="red">Not valid</Tag>
            }
            {
                !props.data.valid &&
                <Tooltip title="A list of errors...">
                    <WarningOutlined style={{color: "red"}}/>
                </Tooltip>
            }

        </Space>
    );

    return (
        <Card
            bordered={true}
            title={<b>{props.data.name}</b>}
            size="small"
            extra={
                <Space>
                    <Button><Link to={`/project/${props.data.project}/design/${props.data.id}`}>Edit</Link></Button>
                    <Button danger type="primary">Delete</Button>
                </Space>
            }
        >
            <Row key={`template-info-${props.data.id}`}>
                <Col style={leftColStyle}>
                    <Space direction="vertical">
                        <span><b>Created:</b> {new Date(props.data.created).toLocaleString()}</span>
                        {validation}
                        {
                            // TODO: Waiting for need data from backend
                            <Tag key={`tag-${props.data.id}-not-use`} color="orange">Not in use</Tag>
                        }
                    </Space>
                </Col>
                <Col style={rightColStyle}>
                    <Space size="middle">
                        {
                            // TODO: Get generated templates
                        }
                        <Image src="https://via.placeholder.com/100"/>
                        <Image src="https://via.placeholder.com/100"/>
                        <Image src="https://via.placeholder.com/100"/>
                        <Image src="https://via.placeholder.com/100"/>
                        <Image src="https://via.placeholder.com/100"/>
                    </Space>
                </Col>
            </Row>
        </Card>
    );
}