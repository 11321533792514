import React, {Component} from 'react';
import {FeedConfigs} from "../FeedConfig/feedConfig";
import {FeedRules} from "../FeedRule/feedRule";
import {Templates} from "../Templates/templates";
import {Info} from "../Info/info"


import {Badge, Button, Card, Col, Dropdown, Menu, message, Popconfirm, Row, Tabs} from 'antd';

import {DownOutlined, QuestionCircleOutlined} from '@ant-design/icons';

const {TabPane} = Tabs;

export class Project extends Component {

    constructor(props) {
        super(props);
        this.state = {
            templatesCount: this.props.project.designs.length,
            feedConfigsCount: this.props.project.outputs.length,
            rulesCount: this.props.project.rules.length
        };
        this.updateTabCounter = this.updateTabCounter.bind(this);
    }

    onDeleteProjectConfirm = () => {
        this.props.delete(this.props.project.id);
    };

    onDeleteProjectCancel = () => {
        message.info({
            content: "Cancelled",
            duration: 3
        })
    }

    onOpenNewRule = () => {
        this.props.handleHeaderUpdate({
            headerType: "header-projects-rename-project",
            headerBreadcrumbIndex: "DOT",
            headerBreadcrumbFirst: "Projects",
            headerBreadcrumbSecond: "Rename project",
            headerTitle: "Rename project",
            headerExtra: [<Button key="btn-back-in-rename-project" onClick={() => this.props.back}>Back</Button>]
        })
    };

    updateTabCounter = (tab, number) => {
        this.setState({
            [tab]: number
        })
    };

    render() {
        // Tab labels
        const infoTab = (
            <span>
            <QuestionCircleOutlined/>
            Info
        </span>
        );

        const designTab = (
            <span>
          <Badge
              count={this.state.templatesCount}
              style={
                  this.state.templatesCount > 0 ?
                      {backgroundColor: '#52c41a', marginRight: '5px'} :
                      {backgroundColor: '#6f6e6e', marginRight: '5px'}
              }
              showZero
          />
            Templates
        </span>
        );

        const rulesSetsTab = (
            <span>
          <Badge
              count={this.state.feedConfigsCount}
              style={this.state.feedConfigsCount > 0 ?
                  {backgroundColor: '#52c41a', marginRight: '5px'} :
                  {backgroundColor: '#6f6e6e', marginRight: '5px'}
              }
              showZero
          />
            Feed Configs
        </span>
        );

        const feedsTab = (
            <span>
          <Badge
              count={this.state.rulesCount}
              style={
                  this.state.rulesCount > 0 ?
                      {backgroundColor: '#52c41a', marginRight: '5px'} :
                      {backgroundColor: '#6f6e6e', marginRight: '5px'}
              }
              showZero
          />
            Rules
        </span>
        );

        // Project Title Menu
        const projectMenu = (
            <>
                <Menu>
                    <Menu.Item onClick={() => this.props.onOpenRenameProjectName(this.props.project.id, this.props.project.name)}>
                        Rename
                    </Menu.Item>
                    <Menu.Item danger>
                        <Popconfirm
                            title="Are you sure to delete this rule?"
                            onConfirm={this.onDeleteProjectConfirm}
                            onCancel={this.onDeleteProjectCancel}
                            okText="Yes, I'm sure"
                            cancelText="No"
                        >
                            Delete it
                        </Popconfirm>
                    </Menu.Item>

                </Menu>
            </>
        );

        const titleWithDropdown = (
            <Dropdown overlay={projectMenu} arrow>
                <span
                    className="ant-dropdown-link"
                    style={{
                        textDecoration: "underline",
                        textDecorationStyle: "dotted",
                        textDecorationColor: "navy",
                        textUnderlineOffset: 5
                    }}
                    onClick={e => e.preventDefault()}
                >
                    {this.props.project.name} <DownOutlined/>
                </span>
            </Dropdown>
        );

        return (
            <Row>
                <Col span={24}>

                    <Card
                        title={titleWithDropdown}
                        bordered={false}
                        style={{boxShadow: "-2px 2px 10px lightgray"}}
                    >
                        <Tabs defaultActiveKey="1">

                            {
                                // Info pane --------------
                            }
                            <TabPane tab={infoTab} key="1">
                                <Info
                                    data={this.props.project.feed}
                                    projectId={this.props.project.id}
                                    fetch={this.props.fetch}
                                />
                            </TabPane>

                            {
                                // Design pane --------------
                            }
                            <TabPane tab={designTab} key="2">
                                <Templates data={this.props.project.designs} projectId={this.props.project.id}/>
                            </TabPane>

                            {
                                // Feed Configs pane --------------
                            }
                            <TabPane tab={rulesSetsTab} key="3">
                                <FeedConfigs
                                    data={this.props.project}
                                    fetch={this.props.fetch}
                                    handleHeaderUpdate={this.props.handleHeaderUpdate}
                                    back={this.props.back}
                                    updateTabCounter={this.updateTabCounter}
                                />
                            </TabPane>

                            {
                                // Feeds pane --------------
                            }
                            <TabPane tab={feedsTab} key="4">
                                <FeedRules
                                    data={this.props.project}
                                    fetch={this.props.fetch}
                                    handleHeaderUpdate={this.props.handleHeaderUpdate}
                                    back={this.props.back}
                                    updateTabCounter={this.updateTabCounter}
                                />
                            </TabPane>
                        </Tabs>
                    </Card>
                </Col>
            </Row>
        );
    }

}